import React from "react";
import styles from "./index.module.css";
import { RespeitoCuidado } from '../../../images';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Typography } from "@mui/material";

const WorkWithusCard = () => {
    return (
        <div className={styles.container} >

            <img src={RespeitoCuidado} alt="Foto" className={styles.img} />

            {/*<Typography className={styles.title} >
                RESPEITO, CUIDADO E
                INVESTIMENTO EM
                NOSSAS PESSOAS
            </Typography>
            <Typography className={styles.subTitle} >
                NOSSO PROGRAMA DE PARTICIPAÇÃO DE
                RESULTADOS DISTRIBUI,ANUALMENTE, 10% DO
                LUCRO DA EMPRESA PARA OS COLABORADORES
            </Typography>

            */}
            <Button onClick={ () => (console.log("teste")) } className={styles.button} variant='outlined' >
              <Link className={styles.menuButtonLink} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to={'/vagas-gerais'}> Trabalhe Conosco </Link>
            </Button>

        </div>
    )
}

export default WorkWithusCard;
