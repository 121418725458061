
import { Routes, Route } from 'react-router-dom';

// Pages Manage
import Admin from './pages/Panel';
import Login from './pages/Login';
import HomeManage from './pages/HomeManage';
import OurStoryManage from './pages/institutional/OurStoryManage';
import WhereAreWeManage from './pages/institutional/WhereAreWeManage';
import SystemManagementManage from './pages/institutional/SystemManagementManage';
import OurVarietiesMange from './pages/Products/OurVarietiesMange';
import RecipesManage from './pages/Products/RecipesManage';
import SustainabilityManage from './pages/SustainabilityManage';
import SchoolOlindinaManage from './pages/AgrodanSocial/SchoolOlindinaManage';
import StudioOlindinaManage from './pages/AgrodanSocial/StudioOlindinaManage';
import UnitHealthManage from './pages/AgrodanSocial/UnitHealthManage';
import ProgramYoungApprenticeManage from './pages/WorkWithUs/ProgramYoungApprenticeManage';
import ProgramInternshipManage from './pages/WorkWithUs/ProgramInternshipManage';
import ProgramTraineeManage from './pages/WorkWithUs/ProgramTraineeManage';
import VacanciesGeneralManage from './pages/WorkWithUs/VacanciesGeneralManage';
import ContactManage from './pages/ContactManage';

import { useAdminContext } from '../contexts/admin';

const Router = () => {
    const { isLoggedIn } = useAdminContext();

    return (
        <Routes>
            <Route path='acess' caseSensitive={true} element={<Login />} />
            {isLoggedIn ?
                <Route path='admin' element={<Admin />} >
                    <Route path='home-manage' element={<HomeManage />} />
                    <Route path='nossa-historia-manage' element={<OurStoryManage />} />
                    <Route path='onde-estamos-manage' element={<WhereAreWeManage />} />
                    <Route path='sistema-gestao-manage' element={<SystemManagementManage />} />
                    <Route path='nossas-variedades-manage' element={<OurVarietiesMange />} />
                    <Route path='receitas-manage' element={<RecipesManage />} />
                    <Route path='sustentabilidade-manage' element={<SustainabilityManage />} />
                    <Route path='escola-olindina-manage' element={<SchoolOlindinaManage />} />
                    <Route path='atelie-olindina-manage' element={<StudioOlindinaManage />} />
                    <Route path='unidade-saude-manage' element={<UnitHealthManage />} />
                    <Route path='programa-jovem-aprendiz-manage' element={<ProgramYoungApprenticeManage />} />
                    <Route path='programa-estagio-manage' element={<ProgramInternshipManage />} />
                    <Route path='programa-trainee-manage' element={<ProgramTraineeManage />} />
                    <Route path='vagas-gerais-manage' element={<VacanciesGeneralManage />} />
                    <Route path='contato-manage' element={<ContactManage />} />
                </Route>
                :
                null
            }
        </Routes>
    )
}

export default Router;