import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import { SistemaGestaoAgrodanText } from '../../../images/institucional';
import UnitManagementBasic from '../../components/SystemManagementCads/UnitManagementBasic';
import StructureSystem from '../../components/SystemManagementCads/StructureSystem';
import IncentiveRecognition from '../../components/SystemManagementCads/IncentiveRecognition';

const SystemManagement = () => {

    return (
        <div className={styles.container} >
            <HeaderBar img={SistemaGestaoAgrodanText} 
            // title='SISTEMA DE GESTÃO AGRODAN' subTitle={''} 
            />

            <UnitManagementBasic />
            <StructureSystem />
            <IncentiveRecognition />
        </div>
    )
}

export default SystemManagement;