import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import JovemAprendiz from '../../../images/jovem_aprendiz.png';
import { Typography, Button } from '@mui/material';
// import Image02 from '../../images/mangas.jpg';

const ProgramYoungApprentice = () => {

    const doubtsData = [
        {
            id: 0,
            title: `QUEM PODE SE INSCREVER ?`,
            subTitle: `Contrary to popular belief, Lorem Ipsum is not simply random
            text. It has roots in a piece of classical Latin literature from
            45 BC, making it over 2000 years old. Richard McClintock, a
            Latin professor at Hampden-Sydney College in Virginia,
            looked up one of the more obscure Latin words, consectetur`
        },
        {
            id: 1,
            title: `QUANTO TEMPO DURA O PROGRAMA ?`,
            subTitle: `Contrary to popular belief, Lorem Ipsum is not simply random
            text. It has roots in a piece of classical Latin literature from
            45 BC, making it over 2000 years old. Richard McClintock, a
            Latin professor at Hampden-Sydney College in Virginia,
            looked up one of the more obscure Latin words, consectetur`
        },
        {
            id: 2,
            title: `QUEM PODE SE INSCREVER ?`,
            subTitle: `Contrary to popular belief, Lorem Ipsum is not simply random
            text. It has roots in a piece of classical Latin literature from
            45 BC, making it over 2000 years old. Richard McClintock, a
            Latin professor at Hampden-Sydney College in Virginia,
            looked up one of the more obscure Latin words, consectetur`
        },
    ]

    return (
        <div className={styles.container} >
            <HeaderBar img={JovemAprendiz} title='' subTitle={''} />

            <div className={styles.sobreProgram} >
                <Typography className={styles.sobreProgramTitle} > SOBRE O PROGRAMA </Typography>
                <Typography className={styles.sobreProgramSubTitle} >
                    Contrary to popular belief, Lorem Ipsum is not simply random
                    text. It has roots in a piece of classical Latin literature from
                    45 BC, making it over 2000 years old. Richard McClintock, a
                    Latin professor at Hampden-Sydney College in Virginia,
                    looked up one of the more obscure Latin words, consectetur
                </Typography>
            </div>

            <div className={styles.buttonBox} >
                <Button variant='outlined' > CLIQUE AQUI PARA SE INSCREVER </Button>
            </div>

            <Typography className={styles.doubtsTitle} > DÚVIDAS FREQUENTES </Typography>

            {doubtsData.map((i, k) => (
                <div key={k} className={styles.doubts} >
                    <Typography className={styles.doubtsText} > {i.title} </Typography>
                    <span className={styles.doubtsDivisor} ></span>
                    <Typography className={styles.doubtsSubText} > {i.subTitle} </Typography>
                </div>
            ))}

        </div>
    )
}

export default ProgramYoungApprentice;
