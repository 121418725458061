import React from "react";
import styles from './styles.module.css';
import { Guidelines, HeaderBar, TimeLine } from '../../components';
import { NossaHistoria } from '../../../images/institucional';
import { Typography } from "@mui/material";
import { Video30Anos } from '../../../images/institucional';
import ReactPlayer from 'react-player';

const OurStory = () => {
    return (
        <div className={styles.container} >
            <HeaderBar
                img={NossaHistoria}
                title='NOSSA HISTÓRIA'
                subTitle={''} />

            <TimeLine />

            <div className={styles.mvv} >
                <Typography className={styles.mvvTitle} >MISSÃO</Typography>
                <Typography className={styles.mvvSubTitle} >
                    Produzir a melhor manga do mundo de forma segura e sustentável, cuidando do meio ambiente, respeitando as pessoas e desenvolvendo a sociedade.
                </Typography>

                <Typography className={styles.mvvTitle} >VISÃO</Typography>
                <Typography className={styles.mvvSubTitle} >
                    Tornar-se líder no Brasil e no mundo em produção e comercialização de mangas premium, destinando 60% do produto para o mercado externo e 40% para o mercado interno.
                </Typography>

                <Typography className={styles.mvvTitle} >VALORES</Typography>
                <Typography className={styles.mvvSubTitle} >
                    Ética, Excelência no Produto, Meritocracia, Respeito pelas Pessoas, Responsabilidade Social, Segurança e Sustentabilidade.
                </Typography>
            </div>

            <Typography className={styles.videoTitle} > VÍDEO 30 ANOS </Typography>
            <Typography className={styles.videoSubTitle} >
                ASSISTA AQUI NOSSO VÍDEO COMEMORATIVO, QUE CELEBROU O ANIVERSÁRIO DE 30 ANOS DA AGRODAN
            </Typography>

            <ReactPlayer url={Video30Anos} controls width={'100%'} style={{ marginTop: '-8vh' }} />
        
            <Guidelines />

        </div>
    )
}

export default OurStory;