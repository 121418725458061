
import { Noticia01, Noticia02, Noticia03, Noticia04 } from '../../images';

export const onNewsPost = () => {
    return new Promise((resolve, reject) => {
        // DATA deve vim do Banco de Dados
        const data = [
            {
                img: Noticia04,
                title: `AGRODAN E UNIVASF FIRMAM PARCERIA PARA PROJETOS NAS ÁREAS DE ENSINO, PESQUISA E EXTENSÃO`,
                subTitle: `Parceria busca aproximar universidade de empresas exportadoras, que possuem grande relevância na região.`,
                data:'10/11/2022',
                text: [
                    {
                        id: 0,
                        paragraph: `“Em nosso trabalho, sempre teremos o que aprender e o que ensinar”, afirma Paulo Dantas,
                    diretor presidente da Agrodan. E com essa visão, a empresa, maior produtora e exportadora de mangas do Brasil,
                    fechou, no mês de março, uma parceria com a Universidade Federal do Vale do São Francisco – UNIVASF.
                    O trabalho conjunto terá uma duração inicial de cinco anos e focará em áreas de interesse mútuo dos envolvidos.`
                    },
                    {
                        id: 1,
                        paragraph: `A iniciativa partiu da Assessoria de Relações Internacionais (ARI), no âmbito do Programa de
                        Internacionalização da Univasf, e teve o acordo assinado pelo vice-reitor no exercício do cargo de reitor,
                        Telio Nobre Leite, e por Paulo  Dantas. A parceria terá projetos e planos específicos apresentados por membros
                        da comunidade acadêmica, que poderão ser realizados estágios para docentes, discentes e técnicos da universidade,
                        projetos para desenvolvimento comunitário e atendimento a populações em estado de vulnerabilidade social, capacitações
                        técnicas para funcionários da Agrodan, desenvolvimento de ações que visem a promoção conjunta da imagem internacional
                         do Vale do São Francisco, entre outros.`
                    },
                    {
                        id: 2,
                        paragraph: `Para Paulo Dantas, a parceria agregará muito valor para ambos. “A Agrodan é uma referência no agronegócio
                        no Brasil e no mundo. Temos muitas práticas de sucesso para apresentar à Univasf, assim como sabemos que a equipe da
                        universidade, sejam alunos ou profissionais, poderão trazer muitas inovações e melhorias para nós.”, afirmou o diretor.`
                    },
                    {
                        id: 3,
                        paragraph: `O assessor de Relações Internacionais da Univasf, Isnaldo Coêlho, ressalta que o leque de possibilidades
                        de interação institucional com as empresas que atuam na fruticultura na região é muito vasto. “Nosso objetivo com esta
                        iniciativa é aglutinar as instituições de ensino e pesquisa e as empresas da região que tenham interesse em promover
                        ações conjuntas, que, entre outros aspectos, possam fortalecer ainda mais a imagem do Vale do São Francisco no país
                        e no exterior, já que somos uma forte região exportadora”, concluiu.`
                    },
                ],
            },
            {
                img: Noticia02,
                title: `PROJETO CONECTADOS: A TRANSFORMAÇÃO DA EDUCAÇÃO POR MEIO DA TECNOLOGIA`,
                subTitle: `Parceria entre Agrodan Social e Fundação Banco do Brasil levará conectividade para mais de 200 crianças do sertão de Pernambuco`,
                data:'10/11/2022',
                text: [
                    {
                        id: 0,
                        paragraph: `Mudar a sociedade por meio da educação sempre foi um sonho do empresário Paulo Dantas,
                        filho de uma professora que atuou durante 35 anos na região do sertão pernambucano. E, transformando
                        o sonho em ação, fundou, em 2016, a Agrodan Social. Foi por meio dessa Associação sem fins lucrativos
                        que nasceu, no ano seguinte, a Escola Professora Olindina Roriz Dantas. Uma escola de ensino infantil
                        e fundamental que, como homenagem, carrega o nome de sua mãe.`
                    },
                    {
                        id: 1,
                        paragraph: `Para ser referência na região, a escola investiu em ensino integral, transporte escolar,
                        quatro refeições diárias, e uma grade de ensino que vai muito além do tradicionalmente exigido.
                        “Aqui, queremos formar mais do que bons profissionais. Queremos formar bons cidadãos. Oferecemos aulas
                        de música, artes, meio ambiente, direitos humanos, esportes, línguas, e estamos fortalecendo cada vez
                        mais a área de tecnologia, com robótica e informática”, afirma Paulo Dantas.`
                    },
                    {
                        id: 2,
                        paragraph: `Mas, mesmo com toda essa estrutura de ensino, a rotina da escola foi afetada com a chegada da
                        pandemia do Coronavírus. “Não esperávamos algo nessa dimensão. As crianças que estudam na Escola Olindina
                        moram em uma zona rural, com difícil acesso à internet e, consequentemente, isso se tornou um grande desafio
                        na produção de aulas remotas, onde pudéssemos dar continuidade ao ensino de forma segura. Nossa equipe pedagógica
                        seguiu inovando e dando o seu melhor, mas as dificuldades de logística eram grandes”, reforçou o diretor da Agrodan Social.`
                    },
                    {
                        id: 3,
                        paragraph: `Paulo afirmou também que viu, nesse momento, a oportunidade de buscar parceiros que acreditassem na
                        educação como forma de melhorar o mundo. “Conseguimos um apoio de provedores de internet na região, a ATEL e
                        Vale Net, e, para a Fundação Banco do Brasil, apresentamos a proposta de oferecer equipamentos de qualidade
                        para as 265 crianças matriculadas na escola. Eles abraçaram nossa causa, gerando um impacto imensurável na
                        vida desses alunos”.`
                    },
                    {
                        id: 4,
                        paragraph: `A Fundação Banco do Brasil possibilitou a compra de 196 notebooks e 69 tablets, além de equipamentos
                        para montagem de um mini estúdio para gravação e transmissão das aulas. Ao todo, o investimento social chegou a
                        R$ 474 mil reais. Para Rogério Biruel, diretor da Fundação BB, a educação é um dos bens mais preciosos que temos,
                        e deve sempre ser incentivada. “É um prazer para a Fundação participar de um projeto como esse, e poder acompanhar
                        de perto o desenvolvimento dessas crianças. Sabemos que a escola já possui diversos indicadores de qualidade de
                        ensino, e, com esse apoio, não temos dúvidas que os resultados irão melhorar. Estamos muito felizes”, concluiu.`
                    },
                    {
                        id: 5,
                        paragraph: `Os equipamentos serão distribuídos para as crianças no início do ano letivo em 2021, todos com a
                        formatação e as configurações de segurança necessárias. Thallyta Vitória tem 12 anos e é estudante do sétimo
                        ano da Escola Olindina, e disse que está realizando um grande sonho. “Eu sempre quis ter um notebook, sempre
                        sonhei com isso. Eu estava usando o celular da minha mãe para estudar, mas nem sempre era legal. Agora eu tenho
                        certeza que vai melhorar. Agradeço muito à Fundação Banco do Brasil e à Agrodan Social por nos dar essa oportunidade”,
                        concluiu a aluna.`
                    },
                ],
            },
            {
                img: Noticia03,
                title: `ESCOLA PROFESSORA OLINDINA OFERECERÁ CURSO TÉCNICO EM CIÊNCIAS DE DADOS, EM PARCERIA COM CESAR SCHOOL`,
                subTitle: `Curso será integrado com o ensino médio, e promoverá o desenvolvimento científico e tecnológico da região do sertão do São Francisco`,
                data:'10/11/2022',
                text: [
                    {
                        id: 0,
                        paragraph: `A partir de 2023, um novo sonho se tornará realidade para todos os alunos da Escola Professora
                        Olindina Roriz Dantas, que, atualmente, conta com turmas até o 9º ano do ensino fundamental. A implantação
                        do ensino médio com habilitação técnica em Ciências de Dados faz parte dos planos de Paulo Dantas, presidente
                        do projeto social, desde sua inauguração, em 2017, e, agora, vem se concretizando junto à parceria firmada com
                        a Cesar School, uma das principais escolas de inovação do Brasil.
                        `
                    },
                    {
                        id: 1,
                        paragraph: `“O primeiro degrau para criar uma escola do zero já foi de uma enorme responsabilidade.
                        Nosso caminho vem sendo de sucesso, colhemos ótimos resultados e, agora, vamos subir mais um degrau com a
                        ampliação desse ensino. Um passo alto e de grandes desafios, mas que com muito trabalho, sonhos, e ousadia,
                        faremos de tudo para dar certo”, afirmou Paulo Dantas. E para oficializar o processo de implantação do novo
                        curso, no dia 12 de janeiro, foi firmado o contrato de parceria entre Agrodan Social e Cesar School. O Diretor
                        Executivo de Educação da escola de inovação, Felipe Furtado, reforçou a importância de levar transformação para a
                        vida dessas pessoas. “Para nós, o projeto já deu certo desde o início. Oferecer a base para que esses jovens tenham
                        protagonismo nos próprios sonhos, e ajudá-los a tornar realidade, é uma honra para nossa equipe”, concluiu.`
                    },
                    {
                        id: 2,
                        paragraph: `O curso terá uma metodologia moderna, que envolve projetos reais e práticos, buscando impactar diretamente
                        a vida das pessoas, e gerando qualidade de aprendizado e empregabilidade para os participantes. A aluna Maria Julia,
                        do 8º ano, disse estar feliz com as novas possibilidades, e grata pelo caminho que poderá seguir. “Em 2017 eu vi essa
                        escola ser construída e ficava muito ansiosa para vê-la pronta. Hoje, não tenho palavras para agradecer o quanto é bom
                        estudar aqui, um lugar que vejo que realmente se preocupa com o nosso futuro”, finalizou.`
                    },
                ],
            },
            {
                img: Noticia01,
                title: `AGRODAN BATE RECORDE E SUPERA 31 MILHÕES DE QUILOS DE MANGA PRODUZIDOS EM 2020`,
                subTitle: `Em um dos anos mais desafiadores da sua história, empresa seguiu crescendo, gerando emprego e alcançando um novo recorde de produção`,
                data:'10/11/2022'
                ,text: [
                    {
                        id: 0,
                        paragraph: `Nos últimos 33 anos, a Agrodan construiu uma história sólida de resultados, sempre tendo como base o
                        desenvolvimento sustentável do negócio. E para seguir crescendo, em 2020, a empresa precisou enfrentar diferentes
                        desafios, que envolveram inovação, redução de custos, aumento de produtividade, e um cuidado ainda maior com cada
                        pessoa que compõe nosso time.`
                    },
                    {
                        id: 1,
                        paragraph: `“O mundo se viu diante de uma pandemia. A crise bateu na porta de muitas empresas, mas nós, como um
                        serviço essencial, não pudemos parar. Colocamos a segurança e a saúde dos nossos colaboradores em primeiro lugar,
                        implantamos as mais diversas medidas de proteção, e seguimos, não só mantendo os empregos, como quebrando recordes
                        aqui dentro”, afirma Paulo Dantas, diretor presidente da Agrodan. Entre esses resultados, está o recorde de produção:
                        31 milhões de quilos de manga produzidos em apenas um ano, superando o número anterior, de 30 milhões, alcançados em
                        2017.`
                    },
                    {
                        id: 2,
                        paragraph: `Para Paulo, o recorde representa não só um resultado expressivo da produção, mas também geração de renda
                        e emprego para a região. “Para nós, crescer só fará sentido se a sociedade vier junto conosco. Por isso, 10% do nosso
                        resultado será revertido para nossos projetos sociais, e mais 10% será dividido entre os colaboradores”. o diretor
                        também reforça que tem como objetivo duplicar a empresa nos próximos anos. “Estamos investindo em pessoas, tecnologia
                        e sustentabilidade, para uma gestão cada vez mais profissional e otimizada. A Agrodan é referência em fruticultura, e
                        queremos seguir dando exemplo sobre como o agronegócio pode ser lucrativo, respeitando o meio ambiente e desenvolvendo
                        a sociedade”.`
                    },
                    {
                        id: 3,
                        paragraph: `Para 2021, os objetivos seguirão desafiadores. “Hoje, 95% do nosso faturamento vem do Mercado Europeu.
                        Estamos abrindo caminho no mercado brasileiro, e também investimos em uma ampliação de 2200m² do nosso Packing House,
                        que já é o maior e mais moderno do Brasil. Com isso, implantaremos novos processos de tratamento da manga, e poderemos
                        explorar novos mercados, em outras regiões do mundo. Estamos otimistas e, uma coisa eu afirmo, a Agrodan promoverá
                        muitas mudanças sociais positivas com cada novo recorde quebrado. Que venham os próximos”, concluiu Dantas.`
                    },
                ],
            },

        ];

        resolve(data);
    })
}
