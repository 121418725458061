import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';

const InputText = ({ title, value, onChange, size, keyAction }) => (
    <div className={styles.container} style={{ width: size }} >
        <Typography
            className={styles.title}
            style={{ visibility: value === '' ? 'hidden' : 'visible' }} >
            {title}
        </Typography>
        <input
            className={styles.input}
            type="text"
            placeholder={title}
            value={value}
            onChange={(e) => onChange(keyAction, e.target.value)}
        />
    </div>
)

export default InputText;