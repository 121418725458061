
// Lista de receitas

import Image02 from '../images/mangas.jpg';
import { MousseManga,Smoothie,SaladaRefrescante,CevicheMangaKani, CheesecakeManga, PicoléMangaChocolate, TilápiaMolhoManga } from '../images/Produtos';

export const onRecipesList = () => {
    const list = [
        {
            id: 0, img: MousseManga, title: `MOUSSE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 1, img: Smoothie, title: `SMOOTHIE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 2, img: SaladaRefrescante, title: `SALADA REFRESCANTE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 3, img: CheesecakeManga, title: `CHEESCAKE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 4, img: TilápiaMolhoManga, title: `PEIXE AO MOLHO`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 5, img: CevicheMangaKani, title: `CEVICHE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        }
    ]

    return new Promise((resolve, reject) => {
        resolve(list);
    });
}

// Sem uso ainda
export const onRecipesFilter = () => {
    const list = [
        {
            id: 0, img: Image02, title: `MOUSSE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 1, img: Image02, title: `SMOOTHIE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 2, img: Image02, title: `SALADA REFRESCANTE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 3, img: Image02, title: `CHEESCAKE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 4, img: Image02, title: `PEIXE AO MOLHO`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 5, img: Image02, title: `CEVICHE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 6, img: Image02, title: `CEVICHE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 7, img: Image02, title: `PEIXE AO MOLHO`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 8, img: Image02, title: `MOUSSE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
    ]

    return new Promise((resolve, reject) => {
        resolve(list);
    });
}

export const onRecipesSugestion = (id) => {
    const list = [
        {
            id: 0, img: Image02, title: `MOUSSE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 1, img: Image02, title: `SMOOTHIE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 2, img: Image02, title: `SALADA REFRESCANTE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 3, img: Image02, title: `CHEESCAKE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 4, img: Image02, title: `PEIXE AO MOLHO`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 5, img: Image02, title: `CEVICHE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 6, img: Image02, title: `CEVICHE`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 7, img: Image02, title: `PEIXE AO MOLHO`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
        {
            id: 8, img: Image02, title: `MOUSSE DE MANGA`,
            subTitle: `O baixo índice de chuvas da região nos permite controlar a produção com muito mais assertividade, por meio da irrigação`,
            modePreparo: `Leve o inhame com a água e o açúcar ao fogo médio e cozinhe até ficar macio (uns 10 minutos). Escorra e reserve. No liquidificador, bata o iogurte com a manga e o inhame cozido por 5 minutos ou até formar um creme liso. Distribua em taças e leve à geladeira por umas 2 horas. Sirva decorado com cubinhos de manga ou a gosto.`
        },
    ]

    return new Promise((resolve, reject) => {
        const filterList = list.filter((e) => e.id !== id).slice(0, 4);
        resolve(filterList);
    });
}

export const onIngredients = (id) => {
    const list = [
        { id: 0, title: `1 inhame sem casca picado (130 g)` },
        { id: 0, title: `400 ml de água` },
        { id: 0, title: `1 colher (sopa) de açúcar` },
        { id: 0, title: `100 g de iogurte grego` },
        { id: 0, title: `2 xícaras (chá) de manga picada (reserve alguns cubinhos para decorar)` },
    ]

    return new Promise((resolve, reject) => {
        resolve(list);
    });
}
