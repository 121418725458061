import React from "react";
import { DrawerBar, FooterBar } from '../components';
import { Outlet } from 'react-router-dom';

const App = () => {
    return (
        <div>
            <DrawerBar />
            <Outlet />
            <FooterBar />
        </div>
    )
}

export default App;