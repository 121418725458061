import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AdminContext = createContext({
    userName: '',
    password: '',
    isLoggedIn: false,
    onLogin: (user, password) => { },
    onLogout: () => { },
});

const AdminProvider = ({ children }) => {

    const location = useLocation();

    const [state, setState] = useState({
        userName: '',
        password: '',
        isLoggedIn: false,
    });

    let { userName, password, isLoggedIn } = state;

    // eslint-disable-next-line
    useEffect(() => { onLoggedIn() }, [location]);

    const onLoggedIn = async () => {
        const data = await JSON.parse(localStorage.getItem('user'));
        // console.log(data.userName, data.isLoggedIn);
        if (data !== null) {
            setState({
                userName: data.userName,
                isLoggedIn: data.isLoggedIn,
                password: data.password,
            })
        }
    }

    const onLogin = (user, password) => {
        localStorage.setItem('user', JSON.stringify({
            userName: user,
            password: password,
            isLoggedIn: true,
        }));
        setState({
            userName: user,
            password: password,
            isLoggedIn: true,
        });
    }

    const onLogout = () => {
        alert('deslogar mesmo ?')
        setState({
            userName: '',
            password: '',
            isLoggedIn: false,
        });
        localStorage.removeItem('user');
    }

    return (
        <AdminContext.Provider value={{ userName, password, isLoggedIn, onLogin, onLogout }} >
            {children}
        </AdminContext.Provider>
    )
}

export function useAdminContext() {
    const context = useContext(AdminContext);
    return context;
}

export default AdminProvider;