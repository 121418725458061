import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Typography, Button, Card, CardContent, CardMedia } from '@mui/material';
import { BsCloudDownloadFill } from 'react-icons/bs';
import { onGuidelines } from '../../../serverless/institucional/nossaHistoria';

const Guidelines = () => {

    const [state, setState] = useState({
        data: [{ id: 0, title: '', img: null, file: null }],
    });

    let { data } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        data = await onGuidelines();
        setState({ ...state, data });
    }

    return (
        <div className={styles.container} >
            <Typography className={styles.title} > DIRETRIZES </Typography>

            {data.map((item, key) => (
                <Card key={key} className={styles.card} >
                    <CardMedia component="img" height="160" image={item.img} alt="Foto" />

                    <CardContent >
                        <a href={item.file} download={item.title} className={item.file !== null ? styles.link : styles.linkOff} >
                            <BsCloudDownloadFill size={20} color='seagreen' />
                            <Button className={styles.button} > {item.title} </Button>
                        </a>
                    </CardContent>
                </Card>
            ))}
        </div>
    )
}

export default Guidelines;