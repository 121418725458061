import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import MapsLocals from '../../components/MapLocals';
import HeaderBar from '../../components/HeaderBar';
import ValleySaoFrancisco from '../../components/ValleySaoFrancisco';
import CustomersList from '../../components/CustomersList';
import { onBanner } from '../../../serverless/institucional/ondeEstamos';

const WhereAreWe = () => {

    const [state, setState] = useState({ imageBanner: null });

    let { imageBanner } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        imageBanner = await onBanner();
        setState({ ...state, imageBanner });
    }

    return (
        <div className={styles.container} >
            <HeaderBar img={imageBanner} 
            // title='ONDE ESTAMOS' subTitle={''} 
            />
            <ValleySaoFrancisco />
            <MapsLocals />
            <CustomersList />
        </div>
    )
}

export default WhereAreWe;