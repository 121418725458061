import React from 'react';
import styles from './styles.module.css';
import { Typography } from '@mui/material';
import { FaLinkedinIn, FaFacebookSquare } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { IoIosArrowUp } from 'react-icons/io';

const FooterBar = () => {
    return (
        <div className={styles.container} >
            
            <Typography className={styles.text} >
                <b>FAZENDA:</b> KM 28 ESTRADA VICINAL BELÉM / IBÓ – ZONA RURAL - BELÉM DO SÃO FRANCISCO (PE) | BRASIL - CEP 56.440-000 | FONE: +55 87 3876-1403
            </Typography>

            <Typography className={styles.text} >
                <b>ESCRITÓRIO:</b> RUA GENERAL JOAQUIM INÁCIO, 790 – SALA 807 / 808 PAISSANDÚ – RECIFE (PE) | BRASIL - CEP 50.070-495 | FONE: +55 81 3081-2602
            </Typography>

            <div className={styles.actionsCard} >
                <a href="https://www.linkedin.com/company/agrodan-agropecuaria-roriz-dantas/mycompany/?viewAsMember=true" target="_blank" rel="noopener noreferrer" >
                    <FaLinkedinIn size={28} color='seagreen' title='Linkedin' />
                </a>
                <a href="https://www.facebook.com/agrodanbrasil" target="_blank" rel="noopener noreferrer" >
                    <FaFacebookSquare size={25} color='seagreen' title='Facebbok' />
                </a>
                <a href="https://www.instagram.com/agrodanbrasil/?hl=pt-br" target="_blank" rel="noopener noreferrer" >
                    <BsInstagram size={25} color='seagreen' /*#405DE6*/ title='Instagram' />
                </a>

                <IoIosArrowUp size={30} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} />
            </div>
        </div>
    )
}

export default FooterBar;