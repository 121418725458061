import React from "react";
import styles from './styles.module.css';
import { HeaderBar } from '../../components';
import { SistemaGestaoAgrodan } from '../../../images/institucional';
import { Typography, Avatar } from "@mui/material";
import { AvataGerencial, AvataHumano, AvataTecnico, PhotoUGBano, PhotoPPR, PhotoQualificacaoTec, PhotoSugestionsMelh } from '../../../images/institucional';

const ManagementSystem = () => {

    const dataNumbers = [
        { number: '1300', title: 'Empregos' },
        { number: '32', title: 'Milhões de kg de manga produzidos em 2021' },
        { number: '6', title: 'Certificações internacionais' },
        { number: '52', title: 'Semanas com produção direta' },
        { number: '1400', title: 'Quantidade de containers' },
    ]

    return (
        <div className={styles.container} >
            <HeaderBar
                img={SistemaGestaoAgrodan}
                title='SISTEMA DE GESTÃO AGRODAN'
                subTitle={''}
            />

            <div className={styles.unidadeBasic} >
                <Typography className={styles.unidadeBasicTitle} > UNIDADES DE GESTÃO BÁSICA </Typography>
                <Typography className={styles.unidadeBasicSubTitle} >
                    Utilizamos um Sistema de Gestão focado em resultados, onde os colaboradores
                    são divididos em Unidades de Gestão Básicas para facilitar a administração,
                    trazendo autonomia e responsabilidades para todos os funcionários. Os grupos
                    são compostos por profissionais de uma área específica, com missão, indicadores
                    e metas definidas de acordo com os nossos objetivos.
                </Typography>
            </div>

            <Typography className={styles.estrutureTitle} > ESTRUTURA DO SISTEMA </Typography>

            <Avatar
                alt="Foto"
                src={AvataGerencial}
                sx={{ width: 150, height: 150 }}
            />
            <Typography className={styles.estrutureText} > HUMANO </Typography>
            <Typography className={styles.estrutureSubText} >
                Busca constante pela auto realização de cada colaborador
            </Typography>

            <Avatar
                alt="Foto"
                src={AvataHumano}
                sx={{ width: 150, height: 150 }}
            />
            <Typography className={styles.estrutureText} > GERENCIAL </Typography>
            <Typography className={styles.estrutureSubText} >
                Conhecimento de procedimentos e liderança para gerir tempo
            </Typography>

            <Avatar
                alt="Foto"
                src={AvataTecnico}
                sx={{ width: 150, height: 150 }}
            />
            <Typography className={styles.estrutureText} > TÉCNICO </Typography>
            <Typography className={styles.estrutureSubText} >
                Qualidade e produtividade para atingir metas e obtenção de lucros
            </Typography>


            <Typography className={styles.incentivTitle} > INCENTIVOS E RECONHECIMENTOS </Typography>

            <div className={styles.incentiv} >
                <img src={PhotoSugestionsMelh} alt="Foto" />
                <Typography className={styles.incentivText} > SUGESTÃO DE MELHORIA </Typography>
            </div>
            
            <div className={styles.incentiv} >
                <img src={PhotoQualificacaoTec} alt="Foto" />
                <Typography className={styles.incentivText} > QUALIFICAÇÃO TÉCNICA </Typography>
            </div>
            
            <div className={styles.incentiv} >
                <img src={PhotoPPR} alt="Foto" />
                <Typography className={styles.incentivText} > PARTICIPAÇÃO NOS LUCROS </Typography>
            </div>
            
            <div className={styles.incentiv} >
                <img src={PhotoUGBano} alt="Foto" />
                <Typography className={styles.incentivText} > VIAGEM UGB DO ANO </Typography>
            </div>

            
            <div className={styles.numbersGestion} >
                <Typography className={styles.numbersGestionTitle} > UMA BOA GESTÃO NOS FAZ ALCANÇAR BONS NÚMEROS </Typography>

                {dataNumbers.map((item, key) => (
                    <div key={key} className={styles.numbersGestionItem} >
                        <Typography className={styles.numbersGestionNumber} > {item.number} </Typography>
                        <Typography className={styles.numbersGestionText} > {item.title} </Typography>
                    </div>
                ))}
            </div>

        </div>
    )
}

export default ManagementSystem;