
import { BrowserRouter } from 'react-router-dom';
import AdminProvider from './contexts/admin';
import RouterMobile from './mobile/Router';
import RouterWeb from './web/Router';
import RouterAdmin from './Admin/Router';


import { isMobile } from 'react-device-detect';
// import Maintenance from './Maintenance';

const Router = () => {
    return (
        <BrowserRouter>
            <AdminProvider>
                <RouterAdmin />
                {isMobile ? <RouterMobile /> : <RouterWeb />}
            </AdminProvider>
        </BrowserRouter>
    )
}

// eslint-disable-next-line
// const RouterMaintenance = () => {
//     return <Maintenance />
// }

export default Router;