
import { CodigoEtica, ManualAGD, ManualAGDSocial, ManualOlindina, PoliticaSGI, PoliticaUsoTerra } from '../../images/institucional';
import { FileManualAGD, FileManualAGDSocial, FileManualOlindina, FilePoliticaSGI, FilePoliticaUsoTerra } from '../../images/institucional';

const onTimeLine = () => {
    return new Promise((resolve, reject) => {
        const data = [
            {
                id: 0,
                year: '1987',
                text: `NASCE A AGRODAN. E PARA QUE ISSO ACONTECESSE, DR. ÁLVARO DANTAS COLOCA SEUS 
                BENS COMO GARANTIA EM UM EMPRÉSTIMO NO BANCO DO BRASIL. SEUS FILHOS, PAULO E JAIRO, 
                INICIAM O EMPREENDIMENTO COM UM PROJETO DE IRRIGAÇÃO DE 400 HA DE UVA, MANGA E BANANA`,
                select: false,
            },
            {
                id: 1,
                year: '1990',
                text: `TRÊS ANOS APÓS O PRIMEIRO PLANTIO COMEÇAR A DAR FRUTOS, A EMPRESA INICIA SUAS 
                VENDAS NO MERCADO INTERNO`,
                select: false,
            },
            {
                id: 2,
                year: '1991',
                text: `INICIAM-SE AS EXPORTAÇÕES E, COM O BOM FATURAMENTO NESTE MERCADO, A DÍVIDA DA 
                EMPRESA É LIQUIDADA COM TRÊS ANOS DE ANTECEDÊNCIA`,
                select: false,
            },
            {
                id: 3,
                year: '1994',
                text: `INÍCIO DO CULTIVO NAS ILHAS DE ÁGUA DOCE DA REGIÃO. A AGRODAN SE TORNA A 
                PRIMEIRA EMPRESA DO BRASIL A TRABALHAR COM A MANGA DA VARIEDADE KENT, SEM FIBRAS.`,
                select: false,
            },
            {
                id: 4,
                year: '2003',
                text: `PACKING HOUSE É AMPLIADO E PASSA A SER O MAIOR DO BRASIL. COM CAPACIDADE DE 
                EMBALAGEM DE ATÉ 40 TONELADAS DE MANGA POR HORA.`,
                select: false,
            },
            {
                id: 5,
                year: '2006',
                text: `A AGRODAN, QUE ATUAVA APENAS EM PERNAMBUCO, ADQUIRE 700 HECTARES DE TERRA NA 
                BAHIA E AMPLIA SUA ÁREA DE PRODUÇÃO PARA OS DOIS ESTADOS.`,
                select: false,
            },
            {
                id: 6,
                year: '2014',
                text: `A EMPRESA ADOTA UM NOVO MÉTODO DE GESTÃO: O SISTEMA DE GESTÃO AGRODAN. 
                COM ELE, AUMENTA-SE A VALORIZAÇÃO DA BASE E EQUIPES GANHAM MAIS AUTONOMIA NA BUSCA DE RESULTADOS`,
                select: false,
            },
            {
                id: 7,
                year: '2017',
                text: `ESCOLA PROFESSORA OLINDINA RORIZ DANTAS, O MAIOR INVESTIMENTO SOCIAL DA AGRODAN, É INAUGURADO. 
                O PROJETO LEVARÁ MAIS EDUCAÇÃO E OPORTUNIDADES PARA AS CRIANÇAS DA REGIÃO.`,
                select: false,
            },
            {
                id: 8,
                year: '2021',
                text: `AGRODAN BATE RECORDE E TEM A MAIOR PRODUÇÃO DA SUA HISTÓRIA, ULTRAPASSANDO 32 MILHÕES DE QUILOS 
                DE MANGA EM UM ÚNICO ANO.`,
                select: false,
            },
        ]
        resolve(data);
    })
}

const onGuidelines = () => {
    return new Promise((resolve, reject) => {
        const data = [
            { id: 0, title: 'CÓDIGO DE ÉTICA E CONDUTA', img: CodigoEtica, file: null },
            { id: 1, title: 'POLÍTICA DO SGI', img: PoliticaSGI, file: FilePoliticaSGI },
            { id: 2, title: 'POLÍTICA DE USO DA TERRA', img: PoliticaUsoTerra, file: FilePoliticaUsoTerra },
            { id: 3, title: 'MANUAL DA MARCA - AGRODAN', img: ManualAGD, file: FileManualAGD },
            { id: 4, title: 'MANUAL DA MARCA - AGD SOCIAL', img: ManualAGDSocial, file: FileManualAGDSocial },
            { id: 5, title: 'MANUAL DA MARCA - ESC OLINDINA', img: ManualOlindina, file: FileManualOlindina },
        ]
        resolve(data);
    });
}

export { onTimeLine, onGuidelines };