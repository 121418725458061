import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';

const InfoCard = () => {
    return (
        <div className={styles.container} >
            <Typography className={styles.title} > InfoCard </Typography>
        </div>
    )
}

export default InfoCard;