import { Typography } from '@mui/material';
import React from 'react';
import styles from './index.module.css';

const UnitManagementBasic = () => {

    return (
        <div className={styles.container} >
            <Typography className={styles.title} > UNIDADES DE GESTÃO BÁSICA </Typography>
            <Typography className={styles.subTitle} >
                Utilizamos um Sistema de Gestão focado em resultados, onde os colaboradores
                são divididos em Unidades de Gestão Básicas para facilitar a administração,
                trazendo autonomia e responsabilidades para todos os funcionários.
                Os grupos são compostos por profissionais de uma área específica, com
                missão, indicadores e metas definidas de acordo com os nossos objetivos.
            </Typography>
        </div>
    )
}

export default UnitManagementBasic;