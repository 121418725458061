import React, { useState } from 'react';
import styles from './index.module.css';
import { Typography, Button, Collapse } from '@mui/material';
import { MdDeleteForever } from 'react-icons/md';
import { BsChevronUp, BsChevronDown } from 'react-icons/bs';
import { onDate } from '../../../../serverless/moment';
import InputText from '../../../components/InputText';
import InputArea from '../../../components/TextArea';

const News = () => {

    const [state, setState] = useState({
        data: [{ id: 0, title: '', subTitle: '', image: null, nameImage: '', date: '', disabled: false, edite: false, }],
        /** DATA: será sempre adiconado na posição [0]
         * a nova noticia, quando salvar, gerar uma nova posição
         * e limpar os valores da posição [0]
         */
    });

    let { data } = state;

    const onChangeData = (type, value) => {
        if (type === 'text') {
            data[0].title = value;
        } else if (type === 'subtext') {
            data[0].subTitle = value;
        } else if (type === 'file') {
            data[0].image = URL.createObjectURL(value);
            data[0].nameImage = value.name;
        } else if (type === 'remove') {
            data[0].image = null;
            data[0].nameImage = '';
        } else if (type === 'add') {
            let newID = data.length;
            let newData = data[0];
            data.shift(); // remove o primeiro elemento
            data.unshift({
                id: newID,
                title: newData.title,
                subTitle: newData.subTitle,
                image: newData.image,
                nameImage: newData.nameImage,
                date: onDate(),
                disabled: newData.disabled,
                edite: newData.edite,
            }); // add na primeira posição 
            data.unshift({
                id: 0,
                title: '',
                subTitle: '',
                image: null,
                nameImage: '',
                date: '',
                disabled: false,
                edite: false,
            }); // add na primeira posição
            console.log('data: ', data)

        } else if (type === 'edite') {
            // console.log(data, value);
            data.filter((e) => e.id === value)[0].edite = !data.filter((e) => e.id === value)[0].edite;
        }
        setState({ ...state, data });
    }

    const ListView = () => {
        return (
            data.slice(1, data.length).map((item, key) => (
                <div className={styles.listView} >
                    <div key={key} className={styles.listViewItem} >
                        <Typography className={styles.listViewItemTitle} > {item.title} </Typography>
                        <Typography className={styles.listViewItemDatas} > {item.date} </Typography>
                        <Button onClick={() => onChangeData('edite', item.id)} >
                            {item.edite ? <BsChevronUp size={20} className={styles.listViewItemIcon} /> : <BsChevronDown size={20} className={styles.listViewItemIcon} />}
                        </Button>

                    </div>
                    <ListViewAccordion item={item} />
                </div>
            ))
        )
    }

    const ListViewAccordion = ({ item }) => {

        const { title, subTitle, image, disabled, edite } = item;
        // const { id, nameImage, date } = item;

        return (
            <Collapse style={{ width: '100%' }} in={edite} unmountOnExit >
                <div className={styles.listViewAccordion} >

                    <div>
                        <Typography> {title} </Typography>
                        <input
                            type="text"
                            placeholder='Título'
                            defaultValue={title}

                        />
                    </div>

                    <Typography> {subTitle} </Typography>
                    <Typography> {disabled ? 'disabled' : 'Não disabled'} </Typography>
                    <img src={image} alt="Foto" />
                </div>
            </Collapse>
        )
    }

    return (
        <div className={styles.container} >
            <Typography className={styles.title} > Notícias </Typography>
            <Typography className={styles.titleNewNoticia} > Adicionar nova notícia </Typography>

            <InputText
                size={'88%'}
                title={'Título'}
                keyAction={'text'}
                value={data[0].title}
                onChange={onChangeData}
            />

            <InputArea
                size={'88%'}
                title={'Subtítulo'}
                keyAction={'subtext'}
                value={data[0].subTitle}
                onChange={onChangeData}
            />

            <InputText
                size={'88%'}
                title={'Subtítulo'}
                keyAction={'subtext'}
                value={data[0].subTitle}
                onChange={onChangeData}
            />

            <div className={styles.inputFile} >
                <label className={styles.inputFileName} >
                    {data[0].nameImage !== '' ? data[0].nameImage : 'nome da imagem'}
                </label>

                <label className={styles.inputFileSelect} >
                    escolher
                    <input
                        type="file"
                        hidden
                        onChange={(e) => onChangeData('file', e.currentTarget.files[0])}
                        accept='image/*' />
                </label>

                <Button onClick={() => onChangeData('remove', null)} disabled={data[0].image !== null ? false : true} >
                    <MdDeleteForever size={25} className={data[0].image !== null ? styles.inputFileIcon : styles.inputFileIconDisabled} />
                </Button>
            </div>

            <Button onClick={() => onChangeData('add', null)} > Adicionar </Button>

            <ListView />

        </div>
    )
}

export default News;