import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import { onValleySaoFrancisco } from '../../../serverless/institucional/ondeEstamos';

const ValleySaoFrancisco = () => {

    const [state, setState] = useState({
        data: [{ id: 0, title: '', text: '', image: null }],
    });

    let { data } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        data = await onValleySaoFrancisco();
        setState({ ...state, data });
    }
    return (
        <div className={styles.container} >

            <Typography className={styles.title} >VALE DO SÃO FRANCISCO</Typography>
            <Typography className={styles.subTitle} >
                A Região do Vale do São Francisco compreende a extensão do Rio São Francisco.
                um dos mais importantes e famosos do Brasil. Nossas sete fazendas estão
                localizadas nos municípios de Belém de São Francisco-PE, Abaré-BA e Curaçá-BA
            </Typography>

            <div className={styles.cards} >

                {data.map((item, key) => (
                    <Card key={key} className={styles.cardItem} >
                        <CardMedia component="img" height="200" alt="Foto" image={item.image} />
                        <CardContent>
                            <Typography className={styles.cardTitle} > {item.title} </Typography>
                            <Typography variant="body2" className={styles.cardSubTitle} >
                                {item.text}
                            </Typography>
                        </CardContent>
                    </Card>
                ))}

            </div>
        </div>
    )
}

export default ValleySaoFrancisco;
