import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import { BannerSustentabilidade, AreaRPPN, ColetaSeletiva, EmpresaAmigaAbelha, EstacaoTratamento, UsinaSolar, ViveiroSertaoVerde } from '../../../images/Sustentabilidade';
import { Typography } from '@mui/material';

const Sustainability = () => {

    const data = [
        {
            id: 0, img: AreaRPPN, title: 'ÁREAS DE RPPN', subTitle: `Crescer preservando nossas áreas de mata nativa é um dos nossos princípios. Assim, destinamos 687 hectares de nossas terras como Reserva Particular do Patrimônio Natural (RPPN), com o objetivo de conservar a diversidade biológica da região.`
        },
        {
            id: 1, img: EstacaoTratamento, title: 'ESTAÇÕES DE TRATAMENTO', subTitle: `Possuímos duas estações de tratamento de água. Uma delas, para reuso de água no Packing House, possui capacidade de tratamento é de 10 mil litros de água por hora. A outra, para uso doméstico, pod tratar até 4,5 mil litros por hora.`
        },
        {
            id: 2, img: ViveiroSertaoVerde, title: 'VIVEIRO SERTÃO VERDE', subTitle: `Nosso viveiro, inaugurado em 2018, possui capacidade de produção de até 30 mil mudas nativas. Apenas em 2022, já reflorestamos uma área de mais de 8 mil hectares com diferentes espécies importantes para a região.`
        },
        {
            id: 3, img: EmpresaAmigaAbelha, title: 'EMPRESA AMIGA DA ABELHA', subTitle: `Somos certificados como Empresa Amiga da Abelha. Isso atesta nosso trabalho para promover o desenvolvimento sustentável de práticas agrícolas, a melhoria do meio ambiente, aumento do número de abelhas no Vale do São Francisco e suporte para as populações locais.`
        },
        {
            id: 4, img: ColetaSeletiva, title: 'COLETA SELETIVA', subTitle: `Na Agrodan, possuímos um centro de Gestão de Tratamento de Resíduos sólidos e recicláveis. Temos uma parceria com duas empresas em Petrolina: uma de reciclagem e um aterro sanitário, onde conseguimos dar descarte correto a 100% desses materiais.`
        },
        {
            id: 5, img: UsinaSolar, title: 'USINA SOLAR', subTitle: `Em 2022, inauguramos com uma Usina Solar, que abastecerá a Fazenda Brandões (nossa matriz), o Packing House, nossa Unidade Básica de Saúde e a vila que se localiza em seu entorno, com energia elétrica limpa e renovável.`
        },
    ]

    return (
        <div className={styles.container} >
            <HeaderBar img={BannerSustentabilidade} title='SUSTENTABILIDADE' subTitle={''} />

            <Typography className={styles.title} > O AGRONEGÓCIO PODE E DEVE SER SUSTENTÁVEL </Typography>
            <Typography className={styles.subTitle} >
                Acreditamos que o nosso segmento deve crescer de forma responsável, cuidando
                da sociedade e do meio ambiente. Para isso, adotamos diferentes práticas que
                fazem da Agrodan uma empresa que não só mantém, mas promove cuidados com a
                natureza, que você poderá saber mais a seguir.
            </Typography>

            {data.map((i, k) => (
                <div key={k} className={styles.card} >
                    <img src={i.img} alt="Foto" className={styles.img} />

                    <div className={styles.cardContext} >

                        <Typography className={styles.cardTitle} > {i.title} </Typography>
                        <Typography className={styles.cardSubTitle} > {i.subTitle} </Typography>

                    </div>

                </div>
            ))}


            <div className={styles.politica} style={{marginBottom: 50}}> 
                <h1> POLÍTICA DE GESTÃO AGRÍCOLA INTEGRADA </h1>

                <p className={styles.fonte_politica} > A Gestão Agrícola Integrada da AGRODAN consiste numa abordagem holística da empresa que proporciona alimentos seguros e uma agricultura mais sustentável. A gestão agrícola da AGRODAN deve ser gerida de acordo com os princípios desta Política e irá demonstrar uma melhoria contínua em toda a exploração agrícola, adaptada ao local onde está inserida. </p>

                <p className={styles.fonte_politica} > A Política de Gestão Agrícola Integrada estabelece os compromissos e objetivos da empresa relativamente à Gestão Agrícola Integrada. Compromissos assumidos pela AGRODAN nesta política:  </p>

                <ul className={[styles.list_politica]} >
                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}}> Cumprir com os requisitos legais e regulamentares nacionais e internacionais relevantes para a produção agrícola, segurança dos alimentos, meio ambiente, saúde e segurança do trabalho; </li>
                    
                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Promover uma gestão agrícola integrada com o objetivo de melhorar e enriquecer o ambiente; </li>
                    
                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Usar tecnologias de monitoramento e gestão de dados, como drones, GPS e softwares de gestão agrícola, que facilitam o controle e planejamento de atividades no campo; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Utilização de recursos e gestão de resíduos eficientes e justificados; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Eliminar ou minimizar todos os tipos de poluição;  </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Otimizar a eficiência energética e hídrica; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Reduzir as emissões de Gases de Efeito Estufa (GEE) e adotar medidas positivas para o clima; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Contribuir com a gestão agrícola integrada e adotar práticas de cultivo sustentáveis, como a gestão da fertilidade/nutrientes do solo, uso de matéria orgânica, gestão da água, proteção e valorização de inimigos naturais importantes por meio de medidas adequadas de proteção das plantas, o manejo integrado de pragas e doenças, a manutenção preventiva dos equipamentos e a agricultura de conservação;  </li>

                    {/* <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Manter a comunicação interativa com as partes interessadas (clientes, fornecedores); </li> */}

                    {/* <li className={[styles.list]} 
                        style={{'font-size': '1vw'}}
                    > Promover um ambiente de trabalho harmônico e seguro aos funcionários, visando o atendimento dos objetivos gerais da empresa; </li> */}
                </ul>

                <p className={styles.fonte_politica} > Essas práticas ajudam a manter a produtividade do solo e a proteger as plantas, minimizando os impactos ambientais da produção agrícola. </p>

                <p className={styles.fonte_politica} > Está política é revisada anualmente e, se necessário, atualizada. A Política é divulgada a todos os trabalhadores, fornecedores relevantes e Prestadores de serviços. A política é relevante para todas as atividades da empresa, incluindo as do setor não-alimentar que influenciem o negócio. </p>
            </div>


            <div className={styles.politica}> 
                <h1> POLÍTICA DA QUALIDADE E SEGURANÇA DE ALIMENTOS </h1>

                <p className={styles.fonte_politica} > Promover a satisfação dos clientes, produzindo e comercializando alimentos in natura de qualidade, seguros e autênticos, através da melhoria contínua dos processos, atendimento às legislações e a qualidade requerida pelos clientes. A fim de atender este compromisso, reconhecemos como principais objetivos de nossa <span style={{fontWeight: 'bold'}} > Política da Qualidade e Segurança de Alimentos: </span> </p>

                <ul className={[styles.list_politica]} >
                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}}> Garantir que a política seja comunicada, implementada e mantida em todos os níveis da organização; </li>
                    
                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Garantir que os colaboradores tenham a motivação e a capacitação necessária para produção de alimentos seguros; </li>
                    
                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Manter todos os controles e ações necessários à produção de alimentos seguros; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Atender aos requisitos estatutários e regulamentares nacionais e internacionais pertinentes, que regem a segurança de alimentos nas versões mais atualizadas, em conformidade ainda com os requisitos dos clientes; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Garantir a periódica análise crítica pela direção, para adequação contínua desta política e do sistema de gestão; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Garantir a disponibilidade de recursos necessários à implementação e manutenção do sistema de gestão; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Garantir o comprometimento com a melhoria contínua dos processos e produtos; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Buscar a satisfação dos clientes, atendendo às suas necessidades e expectativas; </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}} > Manter comunicação interativa com as partes interessadas (clientes, fornecedores); </li>

                    <li className={[styles.list]} 
                        style={{'font-size': '1vw'}}
                    > Promover um ambiente de trabalho harmônico e seguro aos funcionários, visando o atendimento dos objetivos gerais da empresa; </li>
                </ul>

                <p className={styles.fonte_politica} > A alta administração assume a responsabilidade da observância desta política, por si, e através da hierarquia, e estabelece como prioridade para a empresa o cumprimento da política. </p>
            </div>

        </div>
    )
}

export default Sustainability;
