import React from 'react';
import NavigationBar from '../components/NavigationBar';
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';

const App = () => {
    return (
        <div>
            <NavigationBar />
            {/* {props.children} */}
            <Outlet />
            <Footer />
        </div>
    )
}

export default App;