import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';

const HeaderBar = ({ img, title, subTitle }) => {
    return (
        <div className={styles.container} >
            <img src={img} alt="Foto" className={styles.img} />
            {title !== '' ? <Typography className={styles.title} > {title} </Typography> : null}
            {subTitle !== '' ? <Typography className={styles.subTitle} > {subTitle} </Typography> : null}
        </div>
    )
}

export default HeaderBar;