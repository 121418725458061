import React, { useState } from 'react';
import styles from './index.module.css';
import { Button, Menu, MenuItem } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';

const ButtonDropDown = ({ title, itens, disabled }) => {

    const [state, setState] = useState({ anchorEl: null, open: false });
    let { anchorEl, open } = state;

    const pathURL = useLocation().pathname;
    const isPathURL = itens.filter((i) => pathURL.includes(`${i.to}`) ).length >= 1 ? true : false;

    const handleClick = (event) => {
        anchorEl = event.currentTarget;
        open = true;
        setState({ ...state, anchorEl, open });
    };

    const handleClose = () => {
        anchorEl = null;
        open = false;
        setState({ ...state, anchorEl, open });
    };

    return (
        <div title={disabled ? 'Desativado para manutenção' : ''} >
            <Button disabled={disabled} className={disabled ? styles.buttonOff : styles.button} onClick={handleClick} endIcon={open ? <ExpandLess /> : <ExpandMore />} variant={isPathURL ? 'outlined' : 'text'} >
                {title}
            </Button>

            <Menu anchorEl={anchorEl} open={open} onClose={handleClose} >
                {itens.map((i, k) => <MenuItem key={k} className={styles.buttonItem} onClick={handleClose} >
                    <Link className={styles.buttonLink} to={i.to} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} > {i.text} </Link>
                </MenuItem>)}
            </Menu>
        </div>
    )
}

export default ButtonDropDown;