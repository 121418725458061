import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { PhotoPPR, PhotoQualificacaoTec, PhotoSugestionsMelh, PhotoUGBano } from '../../../../images/institucional';

const IncentiveRecognition = () => {

    const dataNumbers = [
        { number: '1300', title: 'Empregos' },
        { number: '32', title: 'Milhões de kg de manga produzidos em 2021' },
        { number: '6', title: 'Certificações internacionais' },
        { number: '52', title: 'Semanas com produção direta' },
        { number: '1400', title: 'Quantidade de containers' },
    ]

    return (
        <div className={styles.container} >

            <Typography className={styles.title} > INCENTIVOS E RECONHECIMENTOS </Typography>

            <div className={styles.card} >

                <div className={styles.cardItem} >
                    <img src={PhotoSugestionsMelh} alt="Foto" className={styles.img} />
                    <Typography className={styles.cardText} > SUGESTÃO DE MELHORIA </Typography>
                </div>

                <div className={styles.cardItem} >
                    <img src={PhotoQualificacaoTec} alt="Foto" className={styles.img} />
                    <Typography className={styles.cardText} > QUALIFICAÇÃO TÉCNICA </Typography>
                </div>

            </div>

            <div className={styles.card} >

                <div className={styles.cardItem} >
                    <img src={PhotoPPR} alt="Foto" className={styles.img} />
                    <Typography className={styles.cardText} > PARTICIPAÇÃO NOS LUCROS </Typography>
                </div>

                <div className={styles.cardItem} >
                    <img src={PhotoUGBano} alt="Foto" className={styles.img} />
                    <Typography className={styles.cardText} > VIAGEM UGB DO ANO </Typography>
                </div>

            </div>

            <div className={styles.managementNumbers} >
                <Typography className={styles.managementNumbersTitle} >
                    UMA BOA GESTÃO NOS FAZ ALCANÇAR BONS NÚMEROS
                </Typography>

                <div className={styles.managementNumbersCard} >

                    {dataNumbers.map((i, k) => (
                        <div key={k} className={styles.managementNumbersItem} >
                            <Typography className={styles.managementNumbersCardTitle} > {i.number} </Typography>
                            <Typography className={styles.managementNumbersCardSubTitle} > {i.title} </Typography>
                        </div>
                    ))}
                </div>

            </div>


        </div>
    )
}

export default IncentiveRecognition;