import React from "react";
import styles from './styles.module.css';
import { HeaderBar, News } from '../../components';
import { BannerPrincipal, OitoFazendas, ConhecaIniciativa, RespeitoCuidado } from '../../../images';
import { Button, Typography } from "@mui/material";

const HomeMobile = () => {
    return (
        <div className={styles.container} >
            <HeaderBar
                img={BannerPrincipal}
                title='A MAIOR PRODUTORA E EXPORTADORA DE MANGAS DO BRASIL'
                subTitle='DESDE 1987 FAZENDO O SERTÃO VIRAR POMAR' />

            <News />

            <div className={styles.boxInfo} >
                <img src={OitoFazendas} alt="Foto" />

                <Typography className={styles.boxInfoText} >
                    SETE FAZENDAS, 1300 HECTARES E 32 MILHÕES DE QUILOS DE MANGA PRODUZIDOS EM UM ANO
                </Typography>

                <Button className={styles.boxInfoButton} variant='outlined' >
                    saiba mais sobre nosso processo produtivo
                </Button>
            </div>

            <div className={styles.boxInfo} >
                <img src={ConhecaIniciativa} alt="Foto" />

                <Typography className={styles.boxInfoText2} >
                    CONHEÇA A INICIATIVA QUE VEM MUDANDO A VIDA DE MAIS DE 300 CRIANÇAS
                </Typography>

                <Button className={styles.boxInfoButton2} variant='outlined' >
                    saiba mais sobre a escola professora olindina
                </Button>
            </div>

            <div className={styles.boxInfo} >
                <img src={RespeitoCuidado} alt="Foto" />

                <Typography className={styles.boxInfoText3} >
                    RESPEITO, CUIDADO E INVESTIMENTO EM NOSSAS PESSOAS
                </Typography>

                <Typography className={styles.boxInfoSubText3} >
                    NOSSO PROGRAMA DE PARTICIPAÇÃO DE RESULTADOS DISTRIBUI, ANUALMENTE, 10% DO LUCRO DA EMPRESA PARA OS COLABORADORES
                </Typography>

                <Button className={styles.boxInfoButton3} variant='outlined' >
                    Trabalhe conosco
                </Button>
            </div>
        </div>
    )
}

export default HomeMobile;