import { Typography } from '@mui/material';
import React from 'react';
import styles from './index.module.css';

const MapsLocals = () => {
    return (
        <div className={styles.container} >
            <Typography className={styles.title} >MERCADO EXTERNO</Typography>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d150098.90834280234!2d-39.176672394262205!3d-8.662891396470048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x70a37b723502599%3A0xf053f3743081e8a5!2sAgrodan%20Agrop%20Roriz%20Dantas!5e0!3m2!1spt-BR!2sbr!4v1651203403320!5m2!1spt-BR!2sbr"
                className={styles.map}
                title='Mapa' />

            <label className={styles.separator} />

            <Typography className={styles.title} >MERCADO INTERNO</Typography>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d150098.90834280234!2d-39.176672394262205!3d-8.662891396470048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x70a37b723502599%3A0xf053f3743081e8a5!2sAgrodan%20Agrop%20Roriz%20Dantas!5e0!3m2!1spt-BR!2sbr!4v1651203403320!5m2!1spt-BR!2sbr"
                className={styles.map}
                title='Mapa' />
        </div>
    )
}

export default MapsLocals;