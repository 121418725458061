import React, { useEffect, useState } from 'react';
import styles from './recipesView.module.css';
import HeaderBar from '../../components/HeaderBar';
import Image01 from '../../../images/mangas.jpg';
// import Image02 from '../../../images/lastNoticias.PNG';
import { Button, Card, CardMedia, CardContent, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { onIngredients, onRecipesSugestion } from '../../../serverless/recipes';

const RecipesView = () => {

    const { item } = useLocation().state;

    const [state, setState] = useState({
        ingredients: [{ id: -1, title: `` }],
        sugestions: [{ id: 0, img: null, title: ``, subTitle: ``, modePreparo: `` }],
    });

    let { ingredients, sugestions } = state;

    useEffect(() => {
        const run = async () => {
            let list = await onIngredients(item.id);
            let sugestionsList = await onRecipesSugestion(item.id);
            setState({ ...state, ingredients: list, sugestions: sugestionsList });
        }
        run();
        //eslint-disable-next-line
    }, []);

    return (
        <div className={styles.container} >

            <HeaderBar img={Image01} title='RECEITAS' subTitle={''} />

            <div className={styles.cardHeader} >
                <img src={item.img} alt="Foto" className={styles.img} />
                <div className={styles.cardHeaderTexts} >
                    <Typography className={styles.cardHeaderTitle} > {item.title} </Typography>

                    <Typography className={styles.cardHeaderSubTitle} >Receita @cozinhandocomdeb</Typography>
                </div>
            </div>

            <Typography className={styles.titleIngredient} > INGREDIENTES </Typography>

            {ingredients.map((i, k) => (
                <Typography key={k} className={styles.titleIngredientItens} >
                    {i.title}
                </Typography>
            ))}

            <Typography className={styles.titleIngredient} > MODO DE PREPARO </Typography>
            <Typography className={styles.titleIngredientItens} >
                {item.modePreparo}
            </Typography>


            {/*
              <TextField className={styles.textField} variant='outlined' label='Seu comentário' multiline rows={6} />
              <Button className={styles.textFieldButton} > Deixe seu comentário </Button>
            */}

            <div className={styles.sugestions} >
                {/*sugestions.map((i, k) => (
                    <Card key={k} sx={{ maxWidth: 345 }} className={styles.sugestionsCard} >
                        <CardMedia
                            component="img"
                            height="140"
                            image={i.img}
                            alt="Foto"
                        />
                        <CardContent>
                            <Typography className={styles.sugestionsCardTitle} > {i.title} </Typography>
                        </CardContent>
                    </Card>
                ))*/}
            </div>
        </div>
    )
}

export default RecipesView;
