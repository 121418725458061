import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';

const InputArea = ({ title, value, onChange, size, keyAction }) => {
    return (
        <div className={styles.container} style={{ width: size }} >
            <Typography
                className={styles.title}
                style={{ visibility: value === '' ? 'hidden' : 'visible' }} >
                {title}
            </Typography>

            <textarea
                className={styles.input}
                type="text" rows={title === 'Subtítulo' ? 3 : 5}
                placeholder={title}
                value={value}
                onChange={(e) => onChange(keyAction, e.target.value)}
            />

        </div>
    )
}

export default InputArea;