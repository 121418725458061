import React from "react";
import styles from './styles.module.css';
import { HeaderBar } from '../../components';
import { ImageLastNoticias } from '../../../images';
import { Typography } from "@mui/material";

const ContactMobile = () => {
    return (
        <div className={styles.container} >
            <HeaderBar
                img={ImageLastNoticias}
                title='FALE COM A GENTE'
                subTitle={''}
            />

            <Typography className={styles.title} > ESCRITÓRIO RECIFE </Typography>
            <Typography className={styles.subTitle} > Rua General Joaquim Inácio, 790 </Typography>
            <Typography className={styles.subTitle} > Sala 807 / 808 Paissandú – Recife (PE) </Typography>
            <Typography className={styles.subTitle} > Brasil-CEP 50.070-495 </Typography>
            <Typography className={styles.subTitle} > TELEFONE: +55 81 3081-2602 </Typography>

            <Typography className={styles.title} > FAZENDA MATRIZ - BRANDÕES </Typography>
            <Typography className={styles.subTitle} > KM 28 Estrada Vicinal Belém / Ibó </Typography>
            <Typography className={styles.subTitle} > Zona Rural Belém do São Francisco (PE) </Typography>
            <Typography className={styles.subTitle} > Brasil-CEP 56.440-000 </Typography>
            <Typography className={styles.subTitle} > TELEFONE: +55 87 3876-1403 </Typography>
            
            <Typography className={styles.contactsTop} > <b>ASSUNTOS GERAIS -</b> agrodan@agrodan.com.br </Typography>
            <Typography className={styles.contacts} > <b>DIRETORIA COMERCIAL -</b> paulo.dantas@agrodan.com.br </Typography>
            <Typography className={styles.contacts} > <b>ASSESSORIA DE COMUNICAÇÃO -</b> maryana.damasceno@agrodan.com.br </Typography>
            <Typography className={styles.contacts} > <b>ENVIO DE CURRÍCULOS -</b> vagas@agrodan.com.br </Typography>
        </div>
    )
}

export default ContactMobile;