import React from 'react';
import styles from './index.module.css';
import { BannerPrincipalText, OitoFazendas, ConhecaIniciativa } from '../../../images';
import { Button, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NewsComponent from '../../components/NewsComponent';
import CertificationsCard from '../../components/CertificationsCard';
import WorkWithusCard from '../../components/WorkWithusCard';
import HeaderBar from '../../components/HeaderBar';

const Home = () => {
    return (
        <div className={styles.container} >

            <HeaderBar
                img={BannerPrincipalText}
                // title='A MAIOR PRODUTORA E EXPORTADORA DE MANGAS DO BRASIL'
                // subTitle={'DESDE 1987 FAZENDO O SERTÃO VIRAR POMAR'}
                />

            <NewsComponent limitNews={true}/>


        </div>
    )
}

export default Home;
