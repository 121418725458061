import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import HeaderBar from '../../../web/components/HeaderBar';
import { ImageMangas } from '../../../images';
import { Typography, Button } from '@mui/material';
import { MdDeleteForever } from 'react-icons/md';
import InputText from '../InputText';

const ViewBar = ({ page }) => {

    const [state, setState] = useState({
        data: { title: '', subTitle: '', image: null, nameImage: '', date: '' },
        newData: { title: '', subTitle: '', image: null, nameImage: '', date: '' },
    });

    let { data, newData } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = () => {
        if (page === 'home') {
            data = {
                title: 'A MAIOR PRODUTORA E EXPORTADORA DE MANGAS DO BRASIL',
                subTitle: 'DESDE 1987 FAZENDO O SERTÃO VIRAR POMAR',
                image: ImageMangas,
                nameImage: 'Logo Agrodan',
                date: '14/07/2022',
            }
        }

        setState({...state, data });
    }

    const onChangeData = (type, value) => {
        if (type === 'text') {
            newData.title = value;
        } else if (type === 'subtext') {
            newData.subTitle = value;
        } else if (type === 'file') {
            newData.image = URL.createObjectURL(value);
            newData.nameImage = value.name;
        } else if (type === 'delete') {
            newData.image = null;
            newData.nameImage = '';
        }
        setState({ ...state, newData });
    }

    return (
        <div className={styles.container} >
            <Typography className={styles.title} >Imagem principal e texto</Typography>
            
            <InputText
                size={'88%'}
                title={'Título'}
                keyAction={'text'}
                value={newData.title}
                onChange={onChangeData}
            />
            
            <InputText
                size={'88%'}
                title={'Subtítulo'}
                keyAction={'subtext'}
                value={newData.subTitle}
                onChange={onChangeData}
            />

            <div className={styles.inputImage} >
                <label className={styles.inputImageName} >
                    {newData.nameImage !== '' ? newData.nameImage : 'nome da imagem'}
                </label>

                <label className={styles.inputImageSelect} >
                    Selecione
                    <input
                        type="file"
                        accept='image/*'
                        hidden
                        onChange={(e) => onChangeData('file', e.target.files[0])} />
                </label>
                <Button onClick={() => onChangeData('delete', null)} disabled={newData.image === null ? true : false} >
                    <MdDeleteForever size={30} className={newData.image === null ? styles.iconDeleteDisabled : styles.iconDelete} />
                </Button>
            </div>

            <Typography className={styles.previewTitle} > PRÉ-VISUALIZAÇÃO </Typography>
            <div className={styles.preview} >
                <HeaderBar
                    img={newData.image !== null ? newData.image : data.image}
                    title={newData.title !== '' ? newData.title : data.title}
                    subTitle={newData.subTitle !== '' ? newData.subTitle : data.subTitle}
                />
            </div>

            <Button> Aplicar Alterações </Button>

        </div>
    )
}

export default ViewBar;