import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Drawer, Button, Typography } from '@mui/material';
import { BsList } from 'react-icons/bs';
import { ImageAgrodanLogo } from '../../../images';
import { useNavigate, useLocation } from 'react-router-dom';

const DrawerBar = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = useState({
        is: false,
        pags: [
            { id: 0, title: 'Início', to: '', select: false },
            { id: 1, title: 'Nossa História', to: 'nossa-historia', select: false },
            { id: 2, title: 'Onde Estamos', to: 'onde-estamos', select: false },
            { id: 3, title: 'Sistema de Gestão', to: 'sistema-gestao', select: false },
            { id: 4, title: 'Contato', to: 'contato', select: false },
        ],
    });

    let { is, pags } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, [location]);

    const onInit = () => {
        pags.map(e => {
            e.select = false;
            return e;
        });
        pags.filter((e) => `/${e.to}` === location.pathname)[0].select = true;
        setState({ ...state, pags });
    }

    const onOpen = () => {
        setState({ ...state, is: !is });
    }

    const onNavigate = (to) => {
        navigate(to);
        onOpen();
    }

    return (
        <div className={styles.container} >

            <Button className={styles.button} onClick={() => onOpen()} >
                <BsList color='white' size={30} />
            </Button>

            <Typography className={styles.title} > Agrodan </Typography>

            <Drawer anchor='left' open={is} onClose={() => onOpen()} >
                <div className={styles.drawerCard} >
                    <img src={ImageAgrodanLogo} alt="Logo" />
                    {pags.map((pag, key) => (
                        <Typography
                            key={key}
                            className={pag.select ? styles.btNavSelect : styles.btNav}
                            onClick={() => onNavigate(pag.to)} >
                            {pag.title}
                        </Typography>
                    ))}
                </div>
            </Drawer>
        </div>
    )
}

export default DrawerBar;