import React from 'react';
import styles from './index.module.css';
import { Typography, Avatar } from '@mui/material';
import Image01 from '../../../images/home/rain_forest.png';
import Image02 from '../../../images/home/nurture.png';
import Image03 from '../../../images/home/sabor & qualidade.png';
import Image04 from '../../../images/home/global_gap.png';
import Image05 from '../../../images/home/albert_heijn.png';
import Image06 from '../../../images/home/primus_gfs.png';
import Image07 from '../../../images/home/ods.JPG';
const CertificationsCard = () => {

    const data = [
        // { img: Image01, title: 'Rainforest Alliance' },
        // { img: Image02, title: 'Nurture' },
        // { img: Image03, title: 'Sabor & Qualidade' },
        // { img: Image07, title: 'ODS' },
        // { img: Image04, title: 'Global G.A.P' },
        // { img: Image05, title: 'Albert Heijn' },
        // { img: Image06, title: 'Primus GFS' },
    ]
//<Avatar key={k} src={i.img} alt={i.title} className={styles.avatar} title={i.title} />
    return (
        <div className={styles.container} >

            {/* <Typography className={styles.title} > CERTIFICAÇÕES </Typography>

            <div className={styles.certificateCard} >
                {data.map((i, k) => <img src={i.img} alt={i.title} className={styles.avatar}/>
                )}
            </div> */}

        </div>
    )
}

export default CertificationsCard;
