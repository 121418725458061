import React from 'react';
import styles from './index.module.css';
import { BannerPrincipalText, OitoFazendas, ConhecaIniciativa } from '../../../images';
import { Button, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NewsComponent from '../../components/NewsComponent';
import CertificationsCard from '../../components/CertificationsCard';
import WorkWithusCard from '../../components/WorkWithusCard';
import HeaderBar from '../../components/HeaderBar';

const Home = () => {
    return (
        <div className={styles.container} >

            <HeaderBar
                img={BannerPrincipalText}
                // title='A MAIOR PRODUTORA E EXPORTADORA DE MANGAS DO BRASIL'
                // subTitle={'DESDE 1987 FAZENDO O SERTÃO VIRAR POMAR'}
                />

            <NewsComponent />

            <div className={styles.boxInfo} >

                <div className={styles.boxInfo01} >
                    <img src={OitoFazendas} alt="Foto" />

                    <div className={styles.boxInfo01Context} >
                        <Typography className={styles.boxInfo01ContextTypography}  >
                            SETE FAZENDAS, 1300
                            HECTARES E 32 MILHÕES
                            DE QUILOS DE MANGA
                            PRODUZIDOS EM UM ANO

                        </Typography>
                        <Button className={styles.boxInfo01ContextButton} variant='outlined' >
                        <Link className={styles.menuButtonLink} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to={'/sistema-gestao-agrodan'}> SAIBA MAIS SOBRE NOSSO
                          PROCESSO PRODUTIVO
                        </Link>



                        </Button>
                    </div>
                </div>

                <div className={styles.boxInfo02} >
                    <div className={styles.boxInfo02Context} >
                        <Typography className={styles.boxInfo02ContextTypography}  >
                              CONHEÇA A INICIATIVA QUE
                              VEM MUDANDO A VIDA DE
                              MAIS DE 300 CRIANÇAS

                        </Typography>
                        <Button className={styles.boxInfo02ContextButton} variant='outlined' >
                          <a href="https://www.agrodansocial.com.br/" target="_blank">
                          SAIBA MAIS SOBRE A ESCOLA
                          PROFESSORA OLINDINA
                          </a>

                        </Button>
                    </div>
                    <img src={ConhecaIniciativa} alt="Foto" />
                </div>

            </div>

            <CertificationsCard />

            <WorkWithusCard />

        </div>
    )
}

export default Home;
