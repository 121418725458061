import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import AgrodanLogo from '../../../images/Agrodan-Logo-New.png';
import { Typography, Collapse } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { BiDockLeft } from 'react-icons/bi';

const AdminNavigation = () => {

    const location = useLocation();
    const navigate = useNavigate();

    const [state, setState] = useState({
        pages: [
            { id: 0, title: 'Home', path: 'home-manage', select: false, itens: [{ id: 0, title: 'Home', path: 'home-manage', select: false }] },
            {
                id: 1, title: 'Institucional', path: 'institutional-manage', select: false, itens: [
                    { id: 0, title: 'Nossa História', path: 'nossa-historia-manage', select: false },
                    { id: 1, title: 'Onde Estamos', path: 'onde-estamos-manage', select: false },
                    { id: 2, title: 'Sistema de Gestão', path: 'sistema-gestao-manage', select: false },
                ]
            },
            {
                id: 2, title: 'Produtos', path: 'produtos-manage', select: false, itens: [
                    { id: 0, title: 'Nossas Variedades', path: 'nossas-variedades-manage', select: false },
                    { id: 1, title: 'Receitas', path: 'receitas-manage', select: false },
                ]
            },
            { id: 3, title: 'Sustentabilidade', path: 'sustentabilidade-manage', select: false, itens: [{ id: 0, title: 'Sustentabilidade', path: 'sustentabilidade-manage', select: false }] },
            {
                id: 4, title: 'Agrodan Social', path: 'agrodan-social-manage', select: false, itens: [
                    { id: 0, title: 'Escola Olindina', path: 'escola-olindina-manage', select: false },
                    { id: 1, title: 'Ateliê Olindina', path: 'atelie-olindina-manage', select: false },
                    { id: 2, title: 'Unidade de Saúde', path: 'unidade-saude-manage', select: false },
                ]
            },
            {
                id: 5, title: 'Trabalhe Conosco', path: 'trabalhe-conosco-manage', select: false, itens: [
                    { id: 0, title: 'Programa Jovem Aprendiz', path: 'programa-jovem-aprendiz-manage', select: false },
                    { id: 1, title: 'Programa Estágio', path: 'programa-estagio-manage', select: false },
                    { id: 2, title: 'Programa Trainee', path: 'programa-trainee-manage', select: false },
                    { id: 3, title: 'Vagas Gerais', path: 'vagas-gerais-manage', select: false },
                ]
            },
            { id: 6, title: 'Contato', path: 'contato-manage', select: false, itens: [{ id: 0, title: 'Contato', path: 'contato-manage', select: false }] },
        ]
    });
    let { pages } = state;

    // eslint-disable-next-line
    useEffect(() => onNavigateLocation(), [location]);

    const onNavigateLocation = () => {
        pages = pages.map((e) => {
            e.itens.filter(i => location.pathname.includes(i.path)).map(i => {
                i.select = true;
                return i;
            });
            e.itens.filter(i => !location.pathname.includes(i.path)).map(i => {
                i.select = false;
                return i;
            });
            e.itens.filter(i => i.select).length > 0 ? e.select = true : e.select = false;
            return e;
        });
        setState({ ...state, pages });
    }

    const onNavigateAccordion = (id) => {

        pages.filter((e) => e.id === id)[0].select = !pages.filter((e) => e.id === id)[0].select;
        // pages.filter((e) => e.id === id)[0].itens.filter((i) => i.select).length > 0 ? pages.filter((e) => e.id === id)[0].select = true : pages.filter((e) => e.id === id)[0] = false;
        setState({ ...state, pages });
    }

    return (
        <div className={styles.container} >
            <div className={styles.boxLogo} >
                <img src={AgrodanLogo} alt="Logo" className={styles.img} onClick={() => navigate('/')} />
                <hr />
            </div>

            {pages.map((item, key) => (
                item.id !== 0 && item.id !== 3 && item.id !== 6 ?
                    <div key={key} className={styles.navigate} >

                        <div className={styles.navigateRow} onClick={() => onNavigateAccordion(item.id)} >
                            <Typography key={key} className={item.select || item.itens.filter((i) => i.select).length > 0 ? styles.titleSelect : styles.title} > {item.title} </Typography>
                            {item.select ? <AiOutlineUp className={item.select || item.itens.filter((i) => i.select).length > 0 ? styles.iconsSelect : styles.icons} size={10} /> : <AiOutlineDown className={item.select || item.itens.filter((i) => i.select).length > 0 ? styles.iconsSelect : styles.icons} size={10} />}
                        </div>

                        <Collapse in={item.select} className={styles.accordion} unmountOnExit >
                            {item.itens.map((e, k) =>
                                <Link key={k} to={e.path} className={styles.link} >
                                    <Typography key={k} className={e.select ? styles.linkTitleSelect : styles.linkTitle} > {e.title} </Typography>
                                    <BiDockLeft className={e.select ? styles.iconsSelect : styles.icons} size={15} />
                                </Link>
                            )}
                        </Collapse>
                    </div>
                    :
                    <div key={key} className={styles.navigate} >

                        <div className={styles.navigateRow} onClick={() => onNavigateAccordion(item.id)} >
                            <Link to={item.path} className={styles.link} >
                                <Typography className={item.select || item.itens.filter((i) => i.select).length > 0 ? styles.titleSelect : styles.title} > {item.title} </Typography>
                                <BiDockLeft className={item.select || item.itens.filter((i) => i.select).length > 0 ? styles.iconsSelect : styles.icons} size={15} />
                            </Link>
                        </div>
                    </div>
            ))}

            {/**
             * Home
             * Contact: Contato
             * OurStory: Nossa Historia
             * OurVarieties: Nassas Variedades
             * ProgramInternship: Programa de Estágio
             * ProgramTrainee: Programa de Trainee
             * ProgramYoungApprentice: Programa Jovem Aprendiz
             * Recipes: Receitas
             * SchoolOlindina: Escola Olindina
             * StudioOlindina: Ateliê Olindina
             * Sustainability: Sustentabilidade
             * SystemManagement: Sistema de Gestão
             * UnitHealth: Unidade de Saúde
             * VacanciesGeneral: Vagas Gerais
             * WhereAreWe: Onde Estamos
             */}

        </div>
    )
}

export default AdminNavigation;