import React from 'react';
import styles from './index.module.css';
import { BsFillCircleFill } from 'react-icons/bs';
import { Typography } from '@mui/material';

const OurStoryTimeline = ({ year, size, onClick, select }) => {

    return (
        <div className={styles.container} >
            <div className={styles.card} onClick={() => onClick(year)} >
                <Typography>{year}</Typography>
                <BsFillCircleFill size={40} color={select ? 'seagreen' : 'chocolate'} />
            </div>
            {/* {size ? <hr></hr> : null} */}
        </div>
    )
}

export default OurStoryTimeline;