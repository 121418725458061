import React, { useState, useEffect } from 'react';
import styles from './styles.module.css';
import { Typography, Card, CardMedia, CardContent, Button } from '@mui/material';
import { onNewsPost } from '../../../serverless/home/news';

const News = () => {

    const [state, setState] = useState({
        data: [{ id: 0, img: null, title: '', subTitle: '', text: [{ id: 0, paragraph: '' }] }],
    });

    let { data } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        data = await onNewsPost();
        setState({ ...state, data });
    }

    return (
        <div className={styles.container}>
            <Typography className={styles.newsTitle} > Notícias </Typography>

            {data.slice(1, 4).map((item, key) => (
                <Card key={key} sx={{ maxWidth: '85vw', marginBottom: '5vh' }} >
                    <CardMedia
                        component="img"
                        height={'200'}
                        image={item.img}
                        alt="Foto" />

                    <CardContent>
                        <Typography className={styles.title} > {item.title} </Typography>
                        <Typography
                            variant="body2"
                            color="text.secondary"
                            className={styles.subTitle} >
                            {item.subTitle}
                        </Typography>
                    </CardContent>
                </Card>
            ))}

            <Button className={styles.button} >Ver todas</Button>
        </div>
    )
}

export default News;