import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { MangasKEITT, MangasKENT, MangasPALMER, MangasTOMMY } from '../../../../images/Produtos';

const HarvestCalendar = () => {

    const dataKent = [
        { month: 'Jan', img: MangasKENT },
        { month: 'Fev', img: MangasKENT },
        { month: 'Mar', img: MangasKENT },
        { month: 'Abr', img: MangasKENT },
        { month: 'Mai', img: MangasKENT },
        { month: 'Jun', img: MangasKENT },
        { month: 'Jul', img: MangasKENT },
        { month: 'Ago', img: MangasKENT },
        { month: 'Set', img: MangasKENT },
        { month: 'Out', img: MangasKENT },
        { month: 'Nov', img: MangasKENT },
        { month: 'Dez', img: MangasKENT },
    ];
    const dataKeitt = [
        { month: 'Jan', img: MangasKEITT },
        { month: 'Fev', img: MangasKEITT },
        { month: 'Mar', img: MangasKEITT },
        { month: 'Abr', img: MangasKEITT },
        { month: 'Mai', img: MangasKEITT },
        { month: 'Jun', img: MangasKEITT },
        { month: 'Jul', img: MangasKEITT },
        { month: 'Ago', img: MangasKEITT },
        { month: 'Set', img: MangasKEITT },
        { month: 'Out', img: MangasKEITT },
        { month: 'Nov', img: MangasKEITT },
        { month: 'Dez', img: MangasKEITT },
    ];
    const dataPalmer = [
        { month: 'Jan', img: MangasPALMER },
        { month: 'Fev', img: MangasPALMER },
        { month: 'Mar', img: MangasPALMER },
        { month: 'Abr', img: MangasPALMER },
        { month: 'Mai', img: MangasPALMER },
        { month: 'Jun', img: MangasPALMER },
        { month: 'Jul', img: MangasPALMER },
        { month: 'Ago', img: MangasPALMER },
        { month: 'Set', img: MangasPALMER },
        { month: 'Out', img: MangasPALMER },
        { month: 'Nov', img: MangasPALMER },
        { month: 'Dez', img: MangasPALMER },
    ];
    const dataTommy = [
        { month: 'Jan', img: MangasTOMMY },
        { month: 'Fev', img: MangasTOMMY },
        { month: 'Mar', img: MangasTOMMY },
        { month: 'Abr', img: MangasTOMMY },
        { month: 'Mai', img: MangasTOMMY },
        { month: 'Jun', img: MangasTOMMY },
        { month: 'Jul', img: MangasTOMMY },
        { month: 'Ago', img: MangasTOMMY },
        { month: 'Set', img: MangasTOMMY },
        { month: 'Out', img: MangasTOMMY },
        { month: 'Nov', img: MangasTOMMY },
        { month: 'Dez', img: MangasTOMMY },
    ];

    return (
        <div className={styles.container} >
            <Typography className={styles.title} > CALENDÁRIO DE SAFRA </Typography>

            <div className={styles.headerCalendar} >
                <Typography className={styles.calendarRowTitle} >  </Typography>
                {dataKent.map((i, k) => <Typography key={k} className={styles.headerCalendarTitle} > {i.month} </Typography>)}
            </div>

            <div className={styles.calendarRowKent} >
                <Typography className={styles.calendarRowTitle} > KENT </Typography>
                {dataKent.map((i, k) => k >= 1 && k <= 7 ? <span className={styles.calendarRowImg} /> : <img key={k} src={i.img} alt="Foto" className={styles.calendarRowImg} />)}
            </div>

            <div className={styles.calendarRowKeitt} >
                <Typography className={styles.calendarRowTitle} > KEITT </Typography>
                {dataKeitt.map((i, k) => <img key={k} src={i.img} alt="Foto" className={styles.calendarRowImg} />)}
            </div>

            <div className={styles.calendarRowPalmer} >
                <Typography className={styles.calendarRowTitle} > PALMER </Typography>
                {dataPalmer.map((i, k) => <img key={k} src={i.img} alt="Foto" className={styles.calendarRowImg} />)}
            </div>

            <div className={styles.calendarRowTommy} >
                <Typography className={styles.calendarRowTitle} > TOMMY </Typography>
                {dataTommy.map((i, k) => <img key={k} src={i.img} alt="Foto" className={styles.calendarRowImg} />)}
            </div>

        </div>
    )
}

export default HarvestCalendar;