import React from 'react';
import styles from './index.module.css';
import { MangasKEITT, MangasKENT, MangasPALMER, MangasTOMMY, FotoComerColher } from '../../../../images/Produtos';
import { Typography } from '@mui/material';

const OurVarietiesList = () => {

    const dataVarieties = [
        {
            img: MangasKENT,
            title: 'KENT',
            subtitle: `A Kent também possui forma oval, mas costuma pegar um pouco mais de cor no pomar, também variando de acordo com o sol, e sua casca pode chegar em tons de vermelho. É doce, com alta quantidade de polpa e não possui fibras.`
        },
        {
            img: MangasKEITT,
            title: 'KEITT',
            subtitle: `A manga Keitt é uma fruta de forma oval, e sua casca é predominantemente verde. Antes de colhida, a depender da quantidade de sol que leva, pode ter coloração mais avermelhada ou amarelada. Sua polpa é doce, levemente ácida e com pouca ou nenhuma fibra.`
        },
        {
            img: MangasPALMER,
            title: 'PALMER',
            subtitle: `A Palmer possui formato alongado. Quando não está madura, sua cor é roxa, e ao longo do amadurecimento, sua casca passa a ficar avermelhada. Ao contrário das anteriores, suas cores não dependem diretamente do sol. A polpa é firme e possui pouca fibra.`
        },
        {
            img: MangasTOMMY,
            title: 'TOMMY',
            subtitle: `A Tommy costuma ser uma fruta que mistura a cor verde e vermelho na casca. Entre as nossas variedades, sua polpa é mais firme e menos cremosa por possuir um teor médio de fibra.`
        },
    ]

    return (
        <div className={styles.container} >

            {dataVarieties.map((i, k) => (
                <div key={k} className={styles.card} >
                    <img src={i.img} alt="Foto" className={styles.img} />

                    <div className={styles.cardItem} >
                        <Typography className={styles.title} > {i.title} </Typography>
                        <Typography className={styles.subTitle} > {i.subtitle} </Typography>
                    </div>

                </div>
            ))}

            <div className={styles.eatSpoon} >

                <img src={FotoComerColher} alt="Fota" className={styles.eatSpoonImg} />

                <div className={styles.eatSpoonItem} >
                    <Typography className={styles.eatSpoonTitle} > {'PARA COMER DE COLHER'} </Typography>
                    <Typography className={styles.eatSpoonSubTitle} >
                        {`Variedades doces, saborosas e sem fibra, aquele famoso fiapo comum em outras mangas. A cremosidade torna a nossa fruta ótima para comer de colher. Experimente!`}
                    </Typography>
                </div>

            </div>

        </div>
    )
}

export default OurVarietiesList;
