import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Button, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NewsCard from '../NewsCard';
import { onNewsPost } from '../../../serverless/home/news';

const NewsComponent = ({limitNews}) => {
    console.log("limit news",limitNews)
    const [state, setState] = useState({
        data: [{ id: 0, img: null, title: '', subTitle: '', text: [{ id: 0, paragraph: '' }] }],
    });

    let { data } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        data = await onNewsPost();
        setState({ ...state, data });
    }

    return (
        <div className={styles.container} >

            <Typography className={styles.title} >NOTÍCIAS</Typography>

            <div className={styles.cardContainer} >
                {data.slice(0, limitNews ? data.length : 3).map((i, k) => <NewsCard key={k} id={i.id} img={i.img} title={i.title} subTitle={i.subTitle} text={i.text} />)}
            </div>

            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} to={`/todas_noticias`}>
              {!limitNews && (<Button className={styles.button} variant={'outlined'} >Ver todas as notícias</Button>) }
            </Link>

        </div>
    )
}

export default NewsComponent;
