import React, { useState, useEffect } from 'react';
import styles from './recipes.module.css';
import { Link } from 'react-router-dom';
import HeaderBar from '../../components/HeaderBar';
import { Grid, Card, CardMedia, CardContent, Typography } from '@mui/material';
import { BsPlusCircle } from 'react-icons/bs';
import { FiMinimize2 } from 'react-icons/fi';
import { BannerReceitas,MousseManga, CevicheMangaKani, CheesecakeManga, PicoléMangaChocolate, TilápiaMolhoManga } from '../../../images/Produtos';
import { onRecipesList } from '../../../serverless/recipes';

const Recipes = () => {

    const [state, setState] = useState({
        visibleAll: false,
        dataRecipes: [
            { id: 0, img: BannerReceitas, title: ``, subTitle: ``, modePreparo: `` },
        ],
    });

    let { visibleAll, dataRecipes } = state;

    useEffect(() => {
        const run = async () => {
            let recipesList = await onRecipesList();
            setState({ ...state, dataRecipes: recipesList });
        }
        run();
        //eslint-disable-next-line
    }, []);


    return (
        <div className={styles.container} >

            <HeaderBar img={BannerReceitas} title='RECEITAS' subTitle={''} />

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className={styles.grid} >
                {dataRecipes.slice(0, visibleAll ? dataRecipes.length : 6).map((i, k) => (
                    <Grid key={k} item xs={6} className={styles.gridItem} >
                        <Link to={`receitas-view`} state={{ item: i }} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} className={styles.link} >
                            <Card className={styles.card} sx={{ maxWidth: 500 }} >
                                <CardMedia className={styles.img} component="img" height="420" image={i.img} alt="Foto" />
                                <CardContent>
                                    <Typography className={styles.title} > {i.title} </Typography>
                                    <Typography className={styles.subTitle} > {i.subTitle} </Typography>
                                </CardContent>
                            </Card>
                        </Link>
                    </Grid>
                ))}
            </Grid>

            {visibleAll ?
                <FiMinimize2 size={40} color='silver' title='Minimizar' onClick={() => setState({ ...state, visibleAll: !visibleAll })} />
                :
                <BsPlusCircle size={40} color='silver' title='Maximizar' onClick={() => setState({ ...state, visibleAll: !visibleAll })} />
            }

        </div>
    )
}

export default Recipes;
