import React from 'react';
import { Typography } from '@mui/material';
import styles from './index.module.css';

import ViewBar from '../../components/ViewBar';
import News from './News';
import InfoCard from './InfoCard';

const HomeManage = () => {

    return (
        <div className={styles.container} >
            <Typography className={styles.title} >Gerenciamento de Home</Typography>

            <ViewBar page={'home'} />

            <News />

            <InfoCard />

        </div>
    )
}

export default HomeManage;