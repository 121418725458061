
import { Routes, Route } from 'react-router-dom';
import App from './pages/App';
import Home from './pages/Home';
import News from './pages/News';
import AllNews from './pages/AllNews';
import OurStory from './pages/OurStory';
import WhereAreWe from './pages/WhereAreWe';
import SystemManagement from './pages/SystemManagement';
import OurVarieties from './pages/OurVarieties';
import Recipes from './pages/Recipes';
import RecipesView from './pages/Recipes/view';
import Sustainability from './pages/Sustainability';
// import SchoolOlindina from './pages/SchoolOlindina';
// import StudioOlindina from './pages/StudioOlindina';
// import UnitHealth from './pages/UnitHealth';
import ProgramYoungApprentice from './pages/ProgramYoungApprentice';
import ProgramInternship from './pages/ProgramInternship';
import ProgramTrainee from './pages/ProgramTrainee';
import VacanciesGeneral from './pages/VacanciesGeneral';
import Contact from './pages/Contact';

// import NotFound from './pages/NotFound';

const Router = () => {

    return (
        <Routes>
            <Route path='/' element={<App />} >
                <Route index element={<Home />} />
                <Route path='nossa-historia' element={<OurStory />} />
                <Route path='onde-estamos' element={<WhereAreWe />} />
                <Route path='noticias' element={<News />} />
                <Route path='todas_noticias' element={<AllNews />} />
                <Route path='sistema-gestao-agrodan' element={<SystemManagement />} />
                <Route path='nossas-variedades' element={<OurVarieties />} />
                <Route path='receitas' element={<Recipes />} />
                <Route path='receitas/receitas-view' element={<RecipesView />} />
                <Route path='sustentabilidade' element={<Sustainability />} />
                {/* <Route path='escola-professora-olindina' element={<SchoolOlindina />} /> */}
                {/* <Route path='atelie-olindina-roriz-dantas' element={<StudioOlindina />} /> */}
                {/* <Route path='unidade-saude-familia-doutor-alvaro-dantas' element={<UnitHealth />} /> */}
                <Route path='programa-jovem-aprendiz' element={<ProgramYoungApprentice />} />
                <Route path='programa-estagio' element={<ProgramInternship />} />
                <Route path='programa-trainee' element={<ProgramTrainee />} />
                <Route path='vagas-gerais' element={<VacanciesGeneral />} />
                <Route path='contato' element={<Contact />} />
            </Route>
            {/* <Route path='/*' element={<NotFound />} /> */}

        </Routes>

    )
}

export default Router;
