import React from 'react';
import styles from './index.module.css';

const StudioOlindinaManage = () => {

    return (
        <div className={styles.container} >
            Ateliê Olindina Manage
        </div>
    )
}

export default StudioOlindinaManage;