import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { BannerPrincipalText, OitoFazendas, ConhecaIniciativa } from '../../../images';
import { Button, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NewsComponent from '../../components/NewsComponent';
import CertificationsCard from '../../components/CertificationsCard';
import WorkWithusCard from '../../components/WorkWithusCard';
import HeaderBar from '../../components/HeaderBar';
import { onNewsPost } from '../../../serverless/home/news';


const News = () => {
  const [state, setState] = useState({
      dados: {text:undefined},
  });

  let { dados } = state;

  useEffect(() => {
      onInit();
      // eslint-disable-next-line
  }, []);


  const location = useLocation();
  dados = location.state;
  const texto = new URLSearchParams(location.search).get('noticia');


  const onInit = async () => {

    // console.log("dados",dados)
     setState({ ...state, dados });
      if(!dados)
      {
        dados = await onNewsPost();
        dados = dados.filter(dado=>dado.title == texto)[0];
        setState({ ...state, dados });
      }
      // console.log("dados",dados)
  }
    return state.dados && state.dados.text && ( <div className={styles.container} >
                <img src={state.dados.img} alt="Foto" className={styles.img} />
                <div className={styles.conteudo}>
                    <h1 className={styles.title}> {state.dados.title} </h1>
                    <h2 className={styles.subTitle}>{state.dados.subTitle} </h2>
                    {
                      state.dados.text.map(paragrafo =>{
                        return(
                          <p>{paragrafo.paragraph}</p>
                        )
                      })
                    }
                </div>
        </div>)

}

export default News;
