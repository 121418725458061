import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import Vagas from '../../../images/vagas.png';
import { Typography, Button } from '@mui/material';

const VacanciesGeneral = () => {

    return (
        <div className={styles.container} >
            <HeaderBar img={Vagas} title='' subTitle={''} />

            <Typography className={styles.text} >
              Venha fazer parte da nossa equipe na Agrodan!

              Estamos em busca de profissionais talentosos e dedicados para se juntar
              a nós em nossa jornada de sucesso. Como uma das principais empresas produtoras de manga do mundo,
              oferecemos um ambiente de trabalho dinâmico e desafiador, onde você terá a oportunidade de desenvolver suas habilidades
              e crescer profissionalmente.

              Nossas vagas são dispostas em diferentes áreas, desde o cultivo e colheita das mangas até a logística e distribuição dos produtos.
              Se você é apaixonado por agricultura, qualidade de alimentos e deseja fazer parte de uma empresa em constante crescimento,
               temos o lugar certo para você.
             </Typography>

            <div className={styles.buttonBox} >
                <Button variant='outlined' > CLIQUE AQUI PARA ENVIAR SEU CURRÍCULO </Button>
            </div>
        </div>
    )
}

export default VacanciesGeneral;
