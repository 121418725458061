import React, { useEffect } from 'react';
import styles from './index.module.css';
import AdminHeaderBar from '../../components/HeaderBar';
import AdminNavigation from '../../components/Navigation';
import { Outlet, useNavigate } from 'react-router-dom';
// import { useAdminContext } from '../../../contexts/admin';

const Admin = () => {

    const navigate = useNavigate();

    useEffect(() => {
        onVerificUser();
        // eslint-disable-next-line
    }, []);

    const onVerificUser = () => {
        const is = JSON.parse(localStorage.getItem('user'));
        if (is === null) {
            navigate('/acess');
        }
    }

    return (
        <div className={styles.container} >
            <AdminHeaderBar />
            <AdminNavigation />
            <Outlet />
        </div>
    )
}

export default Admin;