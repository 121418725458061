import React, { useState } from 'react';
import styles from './index.module.css';
import AgrodanLogo from '../../../images/Agrodan-Logo-New.png';
import { Button, LinearProgress, TextField } from '@mui/material';
import { Login } from '../../../serverless/admin';
import { useAdminContext } from '../../../contexts/admin';
import { useNavigate } from 'react-router-dom';

const LoginAdmin = () => {

    const navigate = useNavigate();
    const { onLogin: onSetLoginContext } = useAdminContext();

    const [state, setState] = useState({
        userData: { user: '', password: '', logado: false },
        isLoading: false,
    });

    let { userData, isLoading } = state;

    const getInputs = (indice, value) => {
        if (indice === 0) {
            userData.user = value;
        } else {
            userData.password = value;
        }
        setState({ ...state, userData });
    }

    const onLogin = async () => {
        setState({ ...state, isLoading: true });
        let { user, password } = userData;
        const dataLogin = await Login(user, password);
        if (dataLogin.logado) {
            onSetLoginContext(dataLogin.user, dataLogin.password); // salvando na context
            navigate('/admin');
        } else {
            alert(dataLogin.logado);
            setState({ ...state, isLoading: false });
        }
    }

    return (
        <div className={styles.container} >
            <img src={AgrodanLogo} alt="Logo" className={styles.img} />
            <TextField className={styles.textField} variant='outlined' label='Usuário' onChange={(e) => getInputs(0, e.target.value)} disabled={isLoading} />
            <TextField className={styles.textField} type='password' variant='outlined' label='Senha' onChange={(e) => getInputs(1, e.target.value)} disabled={isLoading} onKeyDown={(e) => e.key === 'Enter' ? onLogin() : {} } />
            <Button className={styles.button} variant='outlined' onClick={() => onLogin()} disabled={isLoading} >Entrar</Button>
            {isLoading ? <LinearProgress className={styles.linearProgress} /> : null}
        </div>
    )
}

export default LoginAdmin;