import React from 'react';
import styles from './index.module.css';

const UnitHealthManage = () => {

    return (
        <div className={styles.container} >
            Unidade de saúde Manage
        </div>
    )
}

export default UnitHealthManage;