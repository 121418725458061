import React from 'react';
import styles from './index.module.css';
import { Typography, Avatar } from '@mui/material';
import { AvataGerencial, AvataHumano, AvataTecnico } from '../../../../images/institucional';

const StructureSystem = () => {
    return (
        <div className={styles.container} >
            <Typography className={styles.title} > ESTRUTURA DO SISTEMA </Typography>

            <div className={styles.card} >

                <div className={styles.cardItem} >
                    <Avatar
                        alt="Foto"
                        src={AvataHumano}
                        sx={{ width: 182, height: 182 }}
                    />
                    <Typography className={styles.cardTitle} > HUMANO </Typography>
                    <Typography className={styles.cardSubTitle} >
                        Busca constante pela
                        auto realização de
                        cada colaborador
                    </Typography>
                </div>

                <div className={styles.cardItem} >
                    <Avatar
                        alt="Foto"
                        src={AvataGerencial}
                        sx={{ width: 182, height: 182 }}
                    />
                    <Typography className={styles.cardTitle} > GERENCIAL </Typography>
                    <Typography className={styles.cardSubTitle} >
                        Conhecimento de
                        procedimentos e
                        liderança para
                        gerir tempo
                    </Typography>
                </div>

                <div className={styles.cardItem} >
                    <Avatar
                        alt="Foto"
                        src={AvataTecnico}
                        sx={{ width: 182, height: 182 }}
                    />
                    <Typography className={styles.cardTitle} > TÉCNICO </Typography>
                    <Typography className={styles.cardSubTitle} >
                        Qualidade e
                        produtividade para
                        atingir metas e
                        obtenção de lucros
                    </Typography>
                </div>

            </div>
        </div>
    )
}

export default StructureSystem;