
import { OndeEstamosText, SolAnoInteiro, SoloFertil, ComplexoIlhas } from '../../images/institucional';
import { LogoAngeloni, LogoBigBomPreco, LogoBomDia, LogoCarrefour, LogoComper, LogoCovabra } from '../../images/institucional/ondeEstamos/customers';
import { LogoHortifruti, LogoItoFrutas, LogoMambo, LogoOba, LogoPaoAcucar, LogoPrecatu } from '../../images/institucional/ondeEstamos/customers';
import { LogoSamsClub, LogoSavegnago, LogoShimizu, LogoSilvestrin, LogoSuperMuffato, LogoVerona, LogoZaffari, LogoZeppelin } from '../../images/institucional/ondeEstamos/customers';

export const onBanner = () => {
    return new Promise((resolve, reject) => {
        resolve(OndeEstamosText);
    })
}

export const onValleySaoFrancisco = () => {
    return new Promise((resolve, reject) => {
        const data = [
            {
                id: 0,
                title: 'SOL O ANO INTEIRO',
                text: `O baixo índice de chuvas da região nos permite controlar a produção com muito 
                mais assertividade, por meio da irrigação`,
                image: SolAnoInteiro,
            },
            {
                id: 1,
                title: 'SOLO FÉRTIL',
                text: `O baixo índice de chuvas da região nos permite controlar a produção com muito 
                mais assertividade, por meio da irrigação`,
                image: SoloFertil,
            },
            {
                id: 2,
                title: 'COMPLEXO DE ILHAS',
                text: `O baixo índice de chuvas da região nos permite controlar a produção com muito 
                mais assertividade, por meio da irrigação`,
                image: ComplexoIlhas,
            },
        ];
        resolve(data);
    })
}

export const onCustomersList = () => {
    return new Promise((resolve, reject) => {
        const data = [
            { id: 0, title: 'Angeloni', image: LogoAngeloni },
            { id: 1, title: 'Big Bom Preço', image: LogoBigBomPreco },
            { id: 2, title: 'Bom Dia', image: LogoBomDia },
            { id: 3, title: 'Carrefour', image: LogoCarrefour },
            { id: 4, title: 'Comper', image: LogoComper },
            { id: 5, title: 'Covabra', image: LogoCovabra },
            { id: 6, title: 'Hortifruti', image: LogoHortifruti },
            { id: 7, title: 'Ito Frutas', image: LogoItoFrutas },
            { id: 8, title: 'Mambo', image: LogoMambo },
            { id: 9, title: 'Oba', image: LogoOba },
            { id: 10, title: 'Pão de Açúcar', image: LogoPaoAcucar },
            { id: 11, title: 'Precatu', image: LogoPrecatu },
            { id: 12, title: 'Sams Club', image: LogoSamsClub },
            { id: 13, title: 'Savegnago', image: LogoSavegnago },
            { id: 14, title: 'Shimizu', image: LogoShimizu },
            { id: 15, title: 'Silvestrin', image: LogoSilvestrin },
            { id: 16, title: 'Super Muffato', image: LogoSuperMuffato },
            { id: 17, title: 'Verona', image: LogoVerona },
            { id: 18, title: 'Zaffari', image: LogoZaffari },
            { id: 19, title: 'Zeppelin', image: LogoZeppelin },
        ]
        resolve(data);
    })
}