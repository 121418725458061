import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { onCustomersList } from '../../../serverless/institucional/ondeEstamos';
import { Typography } from '@mui/material';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

const CustomersList = () => {

    const [state, setState] = useState({
        count: 0,
        dataCustomers: [{ id: 0, image: null, title: '' },]
    });

    let { count, dataCustomers } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        dataCustomers = await onCustomersList();
        setState({ ...state, dataCustomers });
    }

    const nav = (index) => {
        if (index === 1) {
            if (count < dataCustomers.length) count += 4;
        } else {
            count -= 4;
        }
        setState({ ...state, count });
    }

    return (
        <div className={styles.container} >
            <Typography className={styles.title} >CLIENTES</Typography>

            <div className={styles.cardCustomers} >
                <BsChevronLeft
                    size={40}
                    title='Voltar'
                    className={count > 0 ? styles.left : styles.leftOff}
                    onClick={() => count > 0 ? nav(0) : {}} />

                {dataCustomers.slice(count, count + 4).map((i, k) => <img key={k} src={i.image} className={styles.img} alt={i.title} title={i.title} />)}

                <BsChevronRight
                    size={40}
                    title='Avançar'
                    className={count + 4 < dataCustomers.length ? styles.right : styles.rightOff}
                    onClick={() => count + 4 < dataCustomers.length ? nav(1) : {}} />

            </div>
        </div>
    )
}

export default CustomersList;
