import React from 'react';
import styles from './index.module.css';
import { Typography } from '@mui/material';
import { BsPower } from 'react-icons/bs';
import { useAdminContext } from '../../../contexts/admin';
import { useNavigate } from 'react-router-dom';

const AdminHeaderBar = () => {

    const navigate = useNavigate();
    const { onLogout: onLogoutContext, userName } = useAdminContext();

    const onLogout = () => {
        onLogoutContext();
        navigate('/');
    }

    return (
        <div className={styles.container} >

            <div className={styles.boxTitle} >
                <Typography className={styles.subTitle} > Bem Vindo, </Typography>
                <Typography className={styles.title} > {userName} </Typography>
            </div>

            <BsPower size={25} className={styles.logout} color='white' title='Logout' onClick={() => onLogout()} />
            <hr />
        </div>
    )
}

export default AdminHeaderBar;