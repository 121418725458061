import moment from "moment";

export const onDate = () => {
    return moment().format('DD/MM/YYYY')
}

export const onDateTime = () => {
    return moment().format('DD/MM/YYYY HH:mm:ss')
}

export const onDateTransform = (date) => {
    return moment(date).format('DD/MM/YYYY')
}

export const onDateTimeTransform = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
}