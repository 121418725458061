import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Typography } from '@mui/material';
import { onTimeLine } from '../../../serverless/institucional/nossaHistoria';
import { BsFillCircleFill, BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const TimeLine = () => {

    const [state, setState] = useState({
        dataTimeLine: [{ id: 0, year: '', text: '', select: false }],
    });

    let { dataTimeLine } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        dataTimeLine = await onTimeLine();
        dataTimeLine[0].select = true;
        setState({ ...state, dataTimeLine });
    }

    const onSelect = (key) => {
        dataTimeLine.map(e => {
            e.select = false;
            return e;
        });
        dataTimeLine[key].select = true;
        setState({ ...state, dataTimeLine });
    }

    const year = dataTimeLine.filter(e => e.select)[0]?.year;
    const text = dataTimeLine.filter(e => e.select)[0]?.text;

    return (
        <div className={styles.container} >
            <Typography className={styles.year} > {year} </Typography>
            <Typography className={styles.text} > {text} </Typography>

            <div className={styles.time} >

                {dataTimeLine.map((item, key) => (
                    <Typography key={key} className={styles.timeItem} onClick={() => onSelect(key)} >
                        {item.year}
                        <BsFillCircleFill size={50} color={item.select ? 'seagreen' : 'chocolate'} />
                    </Typography>
                ))}

            </div>

            <div className={styles.arrows} >
                <BsArrowLeft size={15} />
                <Typography> arraste </Typography>
                <BsArrowRight size={15} />
            </div>

        </div>
    )
}

export default TimeLine;