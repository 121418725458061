import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import Image01 from '../../../images/lastNoticias.PNG';
import { Typography, Link } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Contact = () => {

    return (
        <div className={styles.container} >
            <HeaderBar img={Image01} title='FALE COM A GENTE' subTitle={''} />

            <div className={styles.locais} >
                <div className={styles.locaisCard} >
                    <Typography className={styles.locaisTitle} > ESCRITÓRIO RECIFE </Typography>
                    <Typography className={styles.locaisSubTitle} > Rua General Joaquim Inácio, 790 </Typography>
                    <Typography className={styles.locaisSubTitle} > Sala 807 / 808 Paissandú – Recife (PE) </Typography>
                    <Typography className={styles.locaisSubTitle} > Brasil-CEP 50.070-495 </Typography>
                    <Typography className={styles.locaisSubTitle} > TELEFONE: +55 81 3081-2602 </Typography>
                </div>

                <div className={styles.locaisCard} >
                    <Typography className={styles.locaisTitle} > FAZENDA MATRIZ - BRANDÕES </Typography>
                    <Typography className={styles.locaisSubTitle} > KM 28 Estrada Vicinal Belém / Ibó </Typography>
                    <Typography className={styles.locaisSubTitle} > Zona Rural Belém do São Francisco (PE) </Typography>
                    <Typography className={styles.locaisSubTitle} > Brasil-CEP 56.440-000 </Typography>
                    <Typography className={styles.locaisSubTitle} > TELEFONE: +55 87 3876-1403 </Typography>
                </div>
            </div>

           
            <div style={{ 
                display: 'flex',
                alignItems: 'center',
                width: '60%'  
                }}>

                <Typography 
                    href="https://forms.gle/AndExsm9rRiUjmi98" target='_blank'
                    className={[styles.texts, styles.spacing, styles.link]} 
                > QUEIXAS, DENÚNCIAS, SUGESTÕES 
                </Typography>

                <Link href="https://forms.gle/AndExsm9rRiUjmi98" target='_blank' 
                    // style={{ marginLeft: '-6.8em' }}     
                >
                    {/* <OpenInNewIcon  style={{ marginLeft: '-6.8em' }} /> */}
                    Clique aqui
                </Link>

            </div>
            
            <Typography className={styles.texts} > ASSUNTOS GERAIS - agrodan@agrodan.com.br </Typography>
            <Typography className={styles.texts} > DIRETORIA COMERCIAL - paulo.dantas@agrodan.com.br </Typography>
            <Typography className={styles.texts} > ASSESSORIA DE COMUNICAÇÃO - lucas.jose@agrodan.com.br </Typography>
            <Typography className={styles.texts} > ENVIO DE CURRÍCULOS - vagas@agrodan.com.br </Typography>

        </div>
    )
}

export default Contact;