import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Card, CardMedia, CardContent, Typography } from '@mui/material';

const NewsCard = ({id, img, title, subTitle,text, data }) => {
    return (
        <Card className={styles.container} >
            <Link onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} state={{ img:img, title:title, subTitle:subTitle,text: text, data:data }} to={`/noticias?noticia=${title}`}>
                <CardMedia
                    component="img"
                    height="200"
                    width="auto"
                    image={img}
                    alt="Foto" />

            </Link>
                <CardContent>
                    <Typography className={styles.title} >
                        {title.substr(0,100) + (title.length > 100 ? '...':'')}
                    </Typography>
                    <br/>
                    <Typography variant="body2" color="text.secondary" className={styles.subTitle} >
                        {subTitle.substr(0,100) + (subTitle.length > 100 ? '...':'')}
                    </Typography>
                </CardContent>

        </Card>
    )
}

export default NewsCard;
