import React from 'react';
import styles from './index.module.css';
import { Button } from '@mui/material';
import Logo from '../../../images/Agrodan-Logo-New.png';
import ButtonDropDown from '../ButtonDropDown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AiFillSetting } from 'react-icons/ai';
import { useAdminContext } from '../../../contexts/admin';

const NavigationBar = () => {

    const pathURL = useLocation().pathname;
    const navigate = useNavigate();
    const { isLoggedIn } = useAdminContext();

    const itens01 = [
        { text: 'Nossa História', to: '/nossa-historia' },
        { text: 'Onde Estamos', to: '/onde-estamos' },
        { text: 'Sistema de Gestão Agrodan', to: '/sistema-gestao-agrodan' },
    ];
    const itens02 = [
        { text: 'Nossas Variedades', to: '/nossas-variedades' },
        // { text: 'Receitas', to: '/receitas' },
    ];
    // eslint-disable-next-line
    const itens03 = [
        { text: 'Escola Professora Olindina', to: '/escola-professora-olindina' },
        { text: 'Aleliê Olindina Roriz Dantas', to: '/atelie-olindina-roriz-dantas' },
        { text: 'Unidade de Saúde da Família Doutor Álvaro Dantas', to: '/unidade-saude-familia-doutor-alvaro-dantas' },
    ];
    const itens04 = [
        { text: 'Programa Jovem Aprendiz', to: '/programa-jovem-aprendiz' },
        { text: 'Programa de Estágio', to: '/programa-estagio' },
        { text: 'Programa de Trainee', to: '/programa-trainee' },
        { text: 'Vagas Gerais', to: '/vagas-gerais' },
    ];


    return (
        <div className={styles.container} >

            <div className={styles.boxImg} >
                <img src={Logo} alt="Logo" onClick={() => { navigate('/'); window.scrollTo({ top: 0, behavior: 'smooth' }) }} />
            </div>

            <div className={styles.menu} >

                <ButtonDropDown title={'Institucional'} itens={itens01} disabled={false} />

                <ButtonDropDown title={'Produtos'} itens={itens02} disabled={false} />

                <Button className={styles.menuButton} variant={pathURL === '/sustentabilidade' ? 'outlined' : 'text'} >
                    <Link className={styles.menuButtonLink} to={'/sustentabilidade'} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} > Sustentabilidade </Link>
                </Button>

                {/* <ButtonDropDown title={'Agrodan Social'} itens={itens03} /> */}

                {/*<ButtonDropDown title={'Trabalhe Conosco'} itens={itens04} disabled={false} />*/}

                <Button className={styles.menuButton} variant={pathURL === '/contato' ? 'outlined' : 'text'} >
                    <Link className={styles.menuButtonLink} to={'/contato'} onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })} > Contato </Link>
                </Button>


            </div>

            {isLoggedIn ?
                <AiFillSetting
                    className={styles.setting}
                    size={25}
                    title='Painel de Gerenciamento'
                    onClick={() => navigate('/admin')}
                />
                :
                null
            }
        </div>
    )
}

export default NavigationBar;
