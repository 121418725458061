import React, { useState, useEffect } from 'react';
import styles from './index.module.css';
import { NossaHistoriaText } from '../../../images/institucional';
import { Typography } from '@mui/material';
import ReactPlayer from 'react-player';
import { Video30Anos } from '../../../images/institucional';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import OurStoryTimeline from '../../components/OurStoryTimeline';
import Guidelines from '../../components/Guidelines';
import HeaderBar from '../../components/HeaderBar';
import { onTimeLine } from '../../../serverless/institucional/nossaHistoria';

const OurStory = () => {

    const [state, setState] = useState({
        countYear: 0,
        countSelect: 0,
        dataYear: [{ id: 0, year: '', text: '', select: false },],
    });

    let { dataYear, countYear, countSelect } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        dataYear = await onTimeLine();
        dataYear[0].select = true;
        setState({ ...state, dataYear });
    }

    const onNavTimeLine = (nav) => {
        if (nav === 1) {
            if (countYear < dataYear.length) countYear += 5;
        } else {
            countYear -= 5;
        }
        setState({ ...state, countYear });
    }

    const onSelectItem = (year) => {
        countSelect = dataYear.filter(e => e.year === year)[0].id;
        dataYear = dataYear.map(e => {
            e.select = false;
            return e;
        });
        dataYear.filter(e => e.year === year)[0].select = true;

        setState({ ...state, countSelect, dataYear });
    }


    return (
        <div className={styles.container} >

            <HeaderBar img={NossaHistoriaText} 
            // title='NOSSA HISTÓRIA' subTitle={''} 
            />

            <div className={styles.timelineCard} >

                <div className={styles.timelineContext} >
                    <Typography className={styles.timelineTitle} > {dataYear.filter(e => e.id === countSelect)[0].year} </Typography>
                    <Typography className={styles.timelineText} > {dataYear.filter(e => e.id === countSelect)[0].text} </Typography>
                </div>

                <div className={styles.timeline} >
                    <BsChevronLeft
                        size={25}
                        className={countYear > 0 ? styles.timelineIcon : styles.timelineIconOff}
                        onClick={() => countYear > 0 ? onNavTimeLine(0) : {}} />

                    {dataYear.slice(countYear, countYear + 5).map((i, k, arr) =>
                        <OurStoryTimeline
                            key={k}
                            year={i.year}
                            select={i.select}
                            onClick={onSelectItem}
                            size={arr.length - 1 === k ? false : true} />)}


                    <BsChevronRight
                        size={25}
                        className={countYear + 5 < dataYear.length ? styles.timelineIcon : styles.timelineIconOff}
                        onClick={() => countYear + 5 < dataYear.length ? onNavTimeLine(1) : {}} />
                </div>
            </div>

            <div className={styles.mvvContainer} >

                <Typography className={styles.mvvTitle} > MISSÃO </Typography>
                <Typography className={styles.mvvContext} >
                    Produzir a melhor manga do mundo de forma segura e sustentável, cuidando do meio ambiente,
                    respeitando as pessoas e desenvolvendo a sociedade.
                </Typography>

                <Typography className={styles.mvvTitle} > VISÃO </Typography>
                <Typography className={styles.mvvContext} >
                    Tornar-se líder no Brasil e no mundo em produção e comercialização de mangas premium,
                    destinando 60% do produto para o mercado externo e 40% para o mercado interno.
                </Typography>

                <Typography className={styles.mvvTitle} > VALORES </Typography>
                <Typography className={styles.mvvContext} >
                    Ética, Excelência no Produto, Meritocracia, Respeito pelas Pessoas, Responsabilidade Social,
                    Segurança e Sustentabilidade.
                </Typography>
            </div>

            <div className={styles.videoContainer} >
                <Typography className={styles.videoTitle} >VÍDEO 30 ANOS</Typography>
                <Typography className={styles.videoSubTitle} >
                    ASSISTA AQUI NOSSO VÍDEO
                    COMEMORATIVO, QUE CELEBROU O
                    ANIVERSÁRIO DE 30 ANOS DA AGRODAN
                </Typography>
                <ReactPlayer url={Video30Anos} controls width={'80%'} />

            </div>

            <Guidelines />

        </div>
    )
}

export default OurStory;