import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { Button, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { BsCloudDownloadFill } from 'react-icons/bs';
import { onGuidelines } from '../../../serverless/institucional/nossaHistoria';

const Guidelines = () => {

    const [state, setState] = useState({
        dataGuidelines: [{ id: 0, title: '', img: null, file: null },],
    });

    let { dataGuidelines } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        dataGuidelines = await onGuidelines();
        setState({ ...state, dataGuidelines });
    }

    return (
        <div className={styles.container} >
            <Typography className={styles.title} > DIRETRIZES </Typography>

            <div className={styles.cardBox} >

                {dataGuidelines.slice(0, 3).map((i, k) =>
                    <Card key={k} className={styles.card} >
                        <CardMedia component="img" height="260" image={i.img} alt="Foto" />

                        <CardContent className={styles.cardContent} >
                            <a href={i.file} download={i.title} className={i.file === null ? styles.linkOf : styles.link} >
                                <BsCloudDownloadFill size={20} color='seagreen' />
                                <Button className={styles.button} > {i.title} </Button>
                            </a>
                        </CardContent>
                    </Card>
                )}


            </div>

            <div className={styles.cardBox} >

                {dataGuidelines.slice(3).map((i, k) =>
                    <Card key={k} className={styles.card} >
                        <CardMedia component="img" height="260" image={i.img} alt="Foto" />

                        <CardContent className={styles.cardContent} >
                            <a href={i.file} download={i.title} className={i.file === null ? styles.linkOf : styles.link} >
                                <BsCloudDownloadFill size={20} color='seagreen' />
                                <Button className={styles.button} > {i.title} </Button>
                            </a>
                        </CardContent>
                    </Card>
                )}
            </div>

        </div>
    )
}

export default Guidelines;
