import { Routes, Route } from 'react-router-dom';
import App from './pages/App';
import HomeMobile from './pages/Home';
import ContactMobile from './pages/Contact';
import OurStoryMobile from './pages/ourStory';
import ManagementSystemMobile from './pages/ManagementSystem';
import WhereAreWeMobile from './pages/WhereAreWe';

const Router = () => {

    return (
        <Routes>
            <Route path='' element={<App />} >
                <Route index element={<HomeMobile />} />
                <Route path='nossa-historia' element={<OurStoryMobile />} />
                <Route path='onde-estamos' element={<WhereAreWeMobile />} />
                <Route path='sistema-gestao' element={<ManagementSystemMobile />} />
                <Route path='contato' element={<ContactMobile />} />
            </Route>
        </Routes>
    )

}

export default Router;