import React from 'react';
import styles from './index.module.css';
import HeaderBar from '../../components/HeaderBar';
import { BannerVariedades } from '../../../images/Produtos';
import OurVarietiesList from '../../components/OurVarietiesCards/List';
import HarvestCalendar from '../../components/OurVarietiesCards/HarvestCalendar';

const OurVarieties = () => {

    return (
        <div className={styles.container} >
            <HeaderBar img={BannerVariedades} title='NOSSAS VARIEDADES' subTitle={''} />

            <OurVarietiesList />

            <HarvestCalendar />
        </div>
    )
}

export default OurVarieties;