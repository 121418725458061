import React, { useEffect, useState } from "react";
import styles from './styles.module.css';
import { HeaderBar } from '../../components';
import { OndeEstamos } from '../../../images/institucional';
import { Typography, Card, CardMedia, CardContent } from "@mui/material";
import { onValleySaoFrancisco, onCustomersList } from '../../../serverless/institucional/ondeEstamos';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const WhereAreWe = () => {

    const [state, setState] = useState({
        dataVale: [{ id: 0, title: '', text: '', image: null }],
        dataClients: [{ id: 0, title: '', image: null }],
    });

    let { dataVale, dataClients } = state;

    useEffect(() => {
        onInit();
        // eslint-disable-next-line
    }, []);

    const onInit = async () => {
        dataVale = await onValleySaoFrancisco();
        dataClients = await onCustomersList();
        setState({ ...state, dataVale, dataClients });
    }

    return (
        <div className={styles.container} >
            <HeaderBar
                img={OndeEstamos}
                title='ONDE ESTAMOS'
                subTitle={''}
            />

            <Typography className={styles.valeTitle} > VALE DO SÃO FRANCISCO </Typography>
            <Typography className={styles.valeSubTitle} >
                A Região do Vale do São Francisco compreende a extensão do Rio São Francisco.
                Um dos mais importantes e famosos do Brasil. Nossas sete fazendas estão
                localizadas nos municípios de Belém de São Francisco-PE, Abaré-BA e Curaçá-BA
            </Typography>

            {dataVale.map((item, key) => (
                <Card key={key} className={styles.card} >
                    <CardMedia component="img" height="140" alt="Foto" image={item.image} />
                    <CardContent>
                        <Typography className={styles.cardTitle} > {item.title} </Typography>
                        <Typography variant="body2" >
                            {item.text}
                        </Typography>
                    </CardContent>
                </Card>
            ))}

            <div className={styles.mapCard} >
                <Typography className={styles.mapTitle} >MERCADO EXTERNO</Typography>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d150098.90834280234!2d-39.176672394262205!3d-8.662891396470048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x70a37b723502599%3A0xf053f3743081e8a5!2sAgrodan%20Agrop%20Roriz%20Dantas!5e0!3m2!1spt-BR!2sbr!4v1651203403320!5m2!1spt-BR!2sbr"
                    className={styles.map}
                    title='Mapa' />

                <Typography className={styles.mapTitle} >MERCADO INTERNO</Typography>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d150098.90834280234!2d-39.176672394262205!3d-8.662891396470048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x70a37b723502599%3A0xf053f3743081e8a5!2sAgrodan%20Agrop%20Roriz%20Dantas!5e0!3m2!1spt-BR!2sbr!4v1651203403320!5m2!1spt-BR!2sbr"
                    className={styles.map}
                    title='Mapa' />
            </div>

            <Typography className={styles.clientsTitle} > CLIENTES </Typography>
            <div className={styles.clientsCard} >
                {dataClients.map((item, key) => (
                    <img key={key} src={item.image} alt="Foto" />
                ))}
            </div>
            <div className={styles.clientsArrows} >
                <BsArrowLeft size={15} />
                <Typography> arraste </Typography>
                <BsArrowRight size={15} />
            </div>

        </div>
    )
}

export default WhereAreWe;
